import { CheckCircleIcon, CheckIcon, PaperAirplaneIcon } from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react';
import { db, useOutsideClick } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';

function SendEmailButton({ selectedCompany, selectedTemplate, setSelectedTemplate, notifyStaff }) {
  const [templatesVisible, setTemplatesVisible] = useState(false);
  const [templates, setTemplates] = useState([]);

  const [emailSent, setEmailSent] = useState(false);

  const templatesRef = useRef();
  useOutsideClick(templatesRef, () => setTemplatesVisible(false));

  useEffect(() => {
    async function fetchTemplates() {
      const fetchedTemplates = await getDocs(
        collection(db, 'user_data', selectedCompany.id, 'email-templates')
      );
      setTemplates(fetchedTemplates.docs);
    }

    fetchTemplates();
  }, [selectedCompany]);

  function selectTemplate(template) {
    setSelectedTemplate(template);
    setTemplatesVisible(false);
  }

  return (
    <div className="flex flex-col lg:flex-row ml-auto w-[60%] lg:w-auto">
      <div ref={templatesRef} className="w-[100%] relative mb-1 lg:mb-0">
        <button
          onClick={() => setTemplatesVisible((prev) => !prev)}
          className="w-[100%] lg:w-max border rounded-lg py-1.5 lg:mr-2 lg:py-2 px-12"
        >
          {selectedTemplate ? selectedTemplate.data().name : 'Template'}
        </button>
        {templatesVisible && (
          <div
            style={{ top: '-' + 100 * templates.length + '%' }}
            className="absolute bg-white w-[100%] rounded-lg shadow-basic px-1 max-h-60"
          >
            {templates.map((template, i) => (
              <button
                key={i}
                onClick={() => selectTemplate(template)}
                className="select-btn hover:bg-indigo-400"
              >
                {template.data().name}
              </button>
            ))}
          </div>
        )}
      </div>
      <button
        onClick={() => notifyStaff(setEmailSent)}
        className={
          'flex items-center justify-center lg:justify-normal rounded-lg transition-all  text-white py-2 px-6 lg:whitespace-nowrap ' +
          (emailSent ? 'bg-green-500' : 'bg-sky-500')
        }
      >
        {emailSent ? (
          <span className="flex items-center w-32 justify-center">
            Email Sent <CheckIcon className="w-4 h-4 lg:w-5 lg:h-5  ml-2 lg:ml-3 shrink-0" />
          </span>
        ) : (
          <span className="flex items-center justify-center w-32">
            Send Mails{' '}
            <PaperAirplaneIcon className="w-4 h-4 lg:w-5 lg:h-5 rotate-90  ml-2 lg:ml-3 shrink-0" />
          </span>
        )}
      </button>
    </div>
  );
}

export default SendEmailButton;
