import { DocumentIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import Papa from 'papaparse';

function AutomaticAdding({ setAutoUsers, progress }) {
  const [totalUsers, setTotalUsers] = useState(0);
  const [superUsers, setSuperUsers] = useState(0);

  async function handleUploadCsv(e) {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results.data);
        setAutoUsers(results.data);

        // Setting Total Users, Superusers, Document Classifications

        setTotalUsers(results.data.length);

        var fetchedSuperUsers = 0;
        results.data.map((row) => {
          if (row['Rolle'].includes('Super')) {
            fetchedSuperUsers++;
          }
        });

        setSuperUsers(fetchedSuperUsers);
      },
    });
  }

  return (
    <div className="my-2 px-2 flex">
      <label
        className="flex items-center justify-center cursor-pointer w-[40%] bg-indigo-400 p-2 rounded-lg text-white text-lg mr-1"
        htmlFor="userList"
      >
        <DocumentIcon className="w-6 h-6 mr-1.5" /> CSV File
      </label>
      <input onChange={(e) => handleUploadCsv(e)} id="userList" className="hidden" type="file" />
      <div className="border rounded-lg w-[60%] ml-1 p-2">
        <p className="text-xl">Statistics</p>
        <div className="mt-0.5 flex">
          <div className="w-[80%]">
            <p className="text-sm">Total Users:</p>
            <p className="text-sm">Super Users:</p>
            <p className="text-sm">Progress:</p>
          </div>
          <div className="ml-auto mr-0">
            <p className="text-sm">{totalUsers}</p>
            <p className="text-sm">{superUsers}</p>
            <p className="text-sm">{progress}%</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutomaticAdding;
