import { MinusSmIcon } from '@heroicons/react/outline';
import React from 'react';

function UserButton({ user, setSelectedSubsidiaries, emailYear, emailSubsidiary }) {
  function removeUser(year, subsidiary, user) {
    setSelectedSubsidiaries((prev) => {
      const usersCopy = [...prev[year][subsidiary]];
      const newUsers = usersCopy.filter((prevUser) => prevUser.id !== user.id);
      return { ...prev, [year]: { ...prev[year], [subsidiary]: newUsers } };
    });
  }

  return (
    <div className="p-1 pl-2 rounded-lg shadow-basic flex justify-between items-center mb-1.5">
      <span className="text-sm w-2/3">{user.data().email}</span>

      {/* Notice when the last message was sent */}

      {/* 
            <div className='flex items-center w-1/5 text-xs text-neutral-500 ml-auto'>
                <ClockIcon className='w-5 h-5 mr-1.5' />
                <span>Notfied 11.08.2023</span>
            </div> 
        */}

      <button
        onClick={() => removeUser(emailYear, emailSubsidiary, user)}
        className="bg-red-600 rounded-lg text-white p-2 ml-auto mr-0"
      >
        <MinusSmIcon className="w-4 h-4" />
      </button>
    </div>
  );
}

export default UserButton;
