import {
  CalendarIcon,
  CogIcon,
  LibraryIcon,
  OfficeBuildingIcon,
  PlusSmIcon,
  UserAddIcon,
} from '@heroicons/react/outline';
import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth, signOut } from 'firebase/auth';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { auth, db, useAuth } from '../../firebase';
import AddCompany from './AddCompany';
import AddYear from './AddYear';
import ManageClients from './ManageClients';
import AddSpecialForm from './AddSpecialForm';
import SetClientManager from './SetClientManager';

function ManageCompanies({ selectedCompany, setSelectedCompany }) {
  /// Title Specification ///

  useEffect(() => {
    document.title = 'Manage Clients - Add Clients, Document Types and Clients';
  }, []);

  /// HTML Component ///

  return (
    <div className="font-body flex flex-col items-center justify-center h-[100%] py-12 w-[90%] md:w-[70%] lg:w-[50%] mx-auto">
      <AddCompany setSelectedCompany={setSelectedCompany} />
      <div className="h-10"></div>
      <AddYear selectedCompany={selectedCompany} />
      <div className="h-10"></div>
      <ManageClients selectedCompany={selectedCompany} />
      <div className="h-10"></div>
      <AddSpecialForm selectedCompany={selectedCompany} />
      <div className="h-10"></div>
      <SetClientManager selectedCompany={selectedCompany} />
    </div>
  );
}

export default ManageCompanies;
