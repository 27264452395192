import { ChevronDownIcon } from '@heroicons/react/outline';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { db, shortify, useAuth, useOutsideClick } from '../../firebase';
import FormHolder from './FormHolder';
import { PencilAltIcon } from '@heroicons/react/outline';

function EditForm({ selectedCompany }) {
  const currentUser = useAuth();

  const [years, setYears] = useState([]);
  const [forms, setForms] = useState([]);

  const [selectedForm, setSelectedForm] = useState();
  const [formsVisible, setFormsVisible] = useState(false);
  const [formData, setFormData] = useState();
  const [deletedRows, setDeletedRows] = useState([]);

  const [yearFormType, setYearFormType] = useState(false);

  const formsRef = useRef();
  useOutsideClick(formsRef, () => setFormsVisible(false));

  /// Title Specification ///

  useEffect(() => {
    document.title = "Edit Form - Edit a selected Document Type's Form";
  }, []);

  /// Loading available Document Types ///

  useEffect(() => {
    async function fetchYears() {
      const fetchedYears = await getDocs(collection(db, 'user_data', selectedCompany?.id, 'years'));
      setYears(fetchedYears.docs);
    }

    async function fetchForms() {
      const fetchedForms = await getDocs(
        collection(db, 'user_data', selectedCompany?.id, 'special_forms')
      );
      setForms(fetchedForms.docs);
    }

    fetchYears();
    fetchForms();
  }, [selectedCompany]);

  /// Document Type Selection ///

  async function selectYear(year) {
    setYearFormType(true);
    setSelectedForm(year);
    setFormsVisible(false);
    const newForm = await getDoc(
      doc(db, 'user_data', selectedCompany.id, 'years', year.id, 'forms', 'standard_form')
    );
    setFormData(newForm.data());
  }

  async function selectForm(form) {
    setYearFormType(false);
    setSelectedForm(form);
    setFormsVisible(false);

    setFormData(form.data());
  }

  /// Saving Callback Function ///

  async function saveFormData(deletedRows) {
    console.log('Saved');
    console.log(formData);

    if (deletedRows?.length > 0) {
      const subsidiaries = await getDocs(
        collection(db, 'user_data', selectedCompany.id, 'years', selectedForm.id, 'subsidaries')
      );
      console.log('Before reading');
      for (const subsidiary of subsidiaries.docs) {
        const newFieldValues = subsidiary
          .data()
          .fieldValues.filter((row) => !deletedRows.includes(row.id));
        console.log(newFieldValues);
        await updateDoc(
          doc(
            db,
            'user_data',
            selectedCompany.id,
            'years',
            selectedForm.id,
            'subsidaries',
            subsidiary.id
          ),
          {
            fieldValues: newFieldValues,
          }
        );
      }

      setDeletedRows([]);
    }

    console.log('Before Error?');
    await updateDoc(
      doc(db, 'user_data', selectedCompany.id, 'years', selectedForm.id, 'forms', 'standard_form'),
      formData
    );
  }

  async function saveSpecialFormData(deletedRows) {
    console.log('Saved');
    console.log(formData);

    if (deletedRows?.length > 0) {
      const specialEntry = await getDoc(
        doc(db, 'user_data', selectedCompany.id, 'special_entries', selectedForm.id)
      );

      const newFieldValues = specialEntry
        .data()
        .fieldValues.filter((row) => !deletedRows.includes(row.id));
      await updateDoc(
        doc(db, 'user_data', selectedCompany.id, 'special_entries', selectedForm.id),
        {
          fieldValues: newFieldValues,
        }
      );

      setDeletedRows([]);
    }

    await updateDoc(
      doc(db, 'user_data', selectedCompany.id, 'special_forms', selectedForm.id),
      formData
    );
  }

  /// HTML Component ///

  return (
    <>
      <div
        className={
          'w-[100%] flex flex-col font-body p-0 my-0 mx-auto bg-white relative ' +
          (formData ? ' ' : '')
        }
      >
        <div className="bg-white text-lg px-2 py-1 flex items-center fixed w-[100%] z-[30] border-b">
          <div className="flex items-center p-2">
            <div className="p-2 bg-blue-500 text-white rounded-full mr-4">
              <PencilAltIcon className="w-7 h-7" />
            </div>
            <span className="text-xl">Edit Form</span>
          </div>
          <div ref={formsRef} className="ml-8 relative">
            <button
              className="border py-1 px-2 rounded-lg flex justify-between items-center"
              onClick={() => setFormsVisible((prev) => !prev)}
            >
              <span className="w-[150px]">{selectedForm ? selectedForm.id : 'Form'}</span>
              <ChevronDownIcon className="h-6 w-6 inline " />
            </button>
            <div className={formsVisible ? 'block' : 'hidden'}>
              <div className="absolute bg-white rounded-lg shadow-basic mt-1 w-[100%] px-2 z-50 max-h-96 overflow-y-auto">
                {years?.map((year, index) => (
                  <button
                    key={index}
                    onClick={() => selectYear(year)}
                    className={
                      'doc-btn ' +
                      (selectedForm?.id === year.id ? 'bg-neutral-600 text-white ' : '')
                    }
                  >
                    <span className="w-[150px]">{year.id}</span>
                  </button>
                ))}
                {forms.map((form, index) => (
                  <button
                    key={index}
                    onClick={() => selectForm(form)}
                    className={
                      'doc-btn ' +
                      (selectedForm?.id === form.id ? 'bg-neutral-600 text-white ' : '')
                    }
                  >
                    <span className="w-[150px]">{form.id}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        {formData && (
          <FormHolder
            formData={formData}
            setFormData={setFormData}
            saveFunction={yearFormType ? saveFormData : saveSpecialFormData}
          />
        )}
      </div>
    </>
  );
}

export default EditForm;
