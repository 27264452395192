import { CheckIcon, PlusSmIcon } from '@heroicons/react/outline';
import React from 'react';

function SubsidiaryButton({
  subsidiary,
  getPercentage,
  getPercentageColor,
  selectedYear,
  selectedSubsidiaries,
  selectSubsidiary,
}) {
  return (
    <div className={'rounded-lg mt-1 flex items-center ' + getPercentageColor(subsidiary)}>
      <div className="p-1.5 text-white w-1/5">{subsidiary.id}</div>
      <div className="text-sm text-white mr-8">
        {Object.keys(subsidiary.data().roles || {}).length} Contact Persons
      </div>
      <div className="flex items-center ml-auto h-[100%] p-1">
        <div className="text-sm text-white mr-8">
          {getPercentage(subsidiary).fieldsDone} / {getPercentage(subsidiary).fieldsTotal}
        </div>
        <button
          onClick={() => selectSubsidiary(subsidiary)}
          className="rounded-lg text-white h-[100%] p-1.5 shadow-basic hover:scale-105 transition-all"
        >
          {!selectedSubsidiaries?.[selectedYear.id]?.[subsidiary.id] && (
            <PlusSmIcon className="w-5 h-5" />
          )}
          {selectedSubsidiaries?.[selectedYear.id]?.[subsidiary.id] && (
            <CheckIcon className="w-5 h-5" />
          )}
        </button>
      </div>
    </div>
  );
}

export default SubsidiaryButton;
