import { CogIcon, TrashIcon } from '@heroicons/react/outline';
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import NameDropdown from '../../components/NameDropdown';
import IdDropdown from '../../components/IdDropdown';

function LocalFileClassification({ selectedCompany }) {
  const [templatesVisible, setTemplatesVisible] = useState();
  const [yearsVisible, setYearsVisible] = useState();

  const [years, setYears] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);

  const [templates, setTemplates] = useState([]);

  const [selectedYear, setSelectedYear] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();

  const [activeTemplate, setActiveTemplate] = useState();

  const [groups, setGroups] = useState([]);

  /// Loading available Document Types and Templates ///

  useEffect(() => {
    async function fetchYearsAndTemplates() {
      const newYears = await getDocs(collection(db, 'user_data', selectedCompany.id, 'years'));
      setYears(newYears.docs);

      const unsub = onSnapshot(
        query(collection(db, 'user_data', selectedCompany.id, 'templates')),
        (newTemplates) => {
          setTemplates(newTemplates.docs);
        }
      );
      // const newTemplates = await getDocs(collection(db, "user_data", selectedCompany.id, "templates"))
      // setTemplates(newTemplates.docs)
    }

    fetchYearsAndTemplates();
  }, [selectedCompany]);

  /// Document Type Selection ///

  async function selectYear(year) {
    const newSubsidiaries = await getDocs(
      query(collection(db, 'user_data', selectedCompany.id, 'years', year.id, 'subsidaries'))
    );
    setSubsidiaries(newSubsidiaries.docs);
    setSelectedYear(year);
    setYearsVisible(false);
    setTemplatesVisible(true);

    const newGroups = [];
    for (const subsidiary of newSubsidiaries.docs) {
      if (!newGroups.includes(subsidiary.id.replace(/[0-9]/g, ''))) {
        newGroups.push(subsidiary.id.replace(/[0-9]/g, ''));
      }
    }
    setGroups(newGroups);
  }

  /// Template Selection ///

  function selectTemplate(template) {
    setActiveTemplate(template.data());
    setSelectedTemplate(template);
    setTemplatesVisible(false);
  }

  /// Changing Classification ///

  function changeClassification(subsidiary) {
    setActiveTemplate((prev) => {
      const updatedTemplate = { ...prev };
      const { relatedSubsidaries } = updatedTemplate;

      if (relatedSubsidaries?.includes(subsidiary.id)) {
        updatedTemplate.relatedSubsidaries = relatedSubsidaries.filter(
          (subsidiaryId) => subsidiaryId !== subsidiary.id
        );
      } else {
        updatedTemplate.relatedSubsidaries = [...relatedSubsidaries, subsidiary.id];
      }

      return updatedTemplate;
    });
  }

  /// Changing Language Classification for All Documents ///

  function changeAllClassification() {
    const updatedTemplate = { ...activeTemplate };

    const all = subsidiaries.map((subsidiary) => subsidiary.id);

    const check = all.every((subsidiaryId) =>
      updatedTemplate?.relatedSubsidaries.includes(subsidiaryId)
    );
    if (check) {
      updatedTemplate.relatedSubsidaries = updatedTemplate.relatedSubsidaries.filter(
        (subsidiaryId) => !all.includes(subsidiaryId)
      );
    } else {
      for (const subsidiaryId of all) {
        if (!updatedTemplate?.relatedSubsidaries.includes(subsidiaryId)) {
          updatedTemplate.relatedSubsidaries.push(subsidiaryId);
        }
      }
    }
    setActiveTemplate(updatedTemplate);
  }

  /// Saving Template Classification ///

  // Speichern wäre auch über query-Abfrage mit WHERE möglich (vlt)
  async function saveTemplateClassification() {
    await updateDoc(doc(db, 'user_data', selectedCompany.id, 'templates', selectedTemplate.id), {
      relatedSubsidaries: activeTemplate.relatedSubsidaries,
    });

    const newTemplates = await getDocs(
      collection(db, 'user_data', selectedCompany.id, 'templates')
    );
    setTemplates(newTemplates.docs);
  }

  /// HTML Component ///

  return (
    <div className="component-container w-[80%] lg:w-[55%] xl:w-[40%] flex flex-col justify-between ">
      <div className="">
        <div className="component-icon-container bg-amber-400">
          <CogIcon className="w-8 h-8" />
        </div>
        <p className="text-2xl p-1 m-1">Manage Topic Classification</p>
        <div className="mt-3">
          <div className="flex px-2 gap-x-1">
            <IdDropdown
              width={'30%'}
              color={'amber'}
              options={years}
              selectFunction={selectYear}
              selectedOption={selectedYear}
              setDropdownVisible={setYearsVisible}
              dropdownVisible={yearsVisible}
              placeholder={'Document Type'}
            />
            <NameDropdown
              width={'70%'}
              color={'amber'}
              options={templates}
              selectFunction={selectTemplate}
              selectedOption={selectedTemplate}
              setDropdownVisible={setTemplatesVisible}
              dropdownVisible={templatesVisible}
              placeholder={'Topic Template'}
            />
          </div>
          <div className="w-[100%] mt-2 px-2">
            <div
              className={
                'border rounded-lg p-2 flex justify-center flex-wrap' +
                (activeTemplate ? '' : ' hidden')
              }
            >
              <button
                className={
                  'auth-button ' +
                  (subsidiaries
                    ?.map((subsidiary) => subsidiary.id)
                    .every((subsidiaryId) =>
                      activeTemplate?.relatedSubsidaries?.includes(subsidiaryId)
                    )
                    ? 'bg-green-400'
                    : 'bg-red-400')
                }
                onClick={() => changeAllClassification()}
              >
                All
              </button>

              {subsidiaries?.map((subsidiary, index) => (
                <button
                  className={
                    'auth-button ' +
                    (activeTemplate?.relatedSubsidaries?.includes(subsidiary.id)
                      ? 'bg-green-400'
                      : 'bg-red-400')
                  }
                  onClick={() => changeClassification(subsidiary)}
                >
                  {subsidiary.id}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 mx-1">
        <button
          className="w-[100%] p-2 bg-amber-400 text-white rounded-lg hover:shadow-md transition-all"
          onClick={saveTemplateClassification}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default LocalFileClassification;
