import { ClipboardListIcon } from '@heroicons/react/outline';
import { addDoc, collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { db } from '../../firebase';

function AddSpecialForm({ selectedCompany }) {
  const [newFormName, setNewFormName] = useState('');

  const [selectedYear, setSelectedYear] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();

  const [forms, setForms] = useState([]);
  const [templates, setTemplates] = useState();

  const [fromTemplate, setFromTemplate] = useState(false);

  const [subsVisible, setSubsVisible] = useState();

  const [availableSubs, setAvailableSubs] = useState();
  const [selectedSubs, setSelectedSubs] = useState([]);

  const [subQuery, setSubQuery] = useState('');

  /// Loading available Document Types ///

  useEffect(() => {
    if (selectedCompany) {
      async function fetchForms() {
        const newYears = await getDocs(
          collection(db, 'user_data', selectedCompany?.id, 'special_forms')
        );
        setForms(newYears?.docs);
      }

      fetchForms();
    }
  }, [selectedCompany]);

  /// Draft Selection ///

  async function selectTemplate(template) {
    setSelectedTemplate(template);
  }

  /// Creating new Document Type ///

  async function addForm() {
    if (forms.map((form) => form.id).includes(newFormName)) {
      return;
    }

    if (!fromTemplate) {
      // Creating new Form

      const newForm = await setDoc(
        doc(db, 'user_data', selectedCompany.id, 'special_forms', newFormName),
        {
          categories: [],
        }
      );

      // Creating new Entries

      const newEntries = await setDoc(
        doc(db, 'user_data', selectedCompany.id, 'special_entries', newFormName),
        {
          fieldValues: [],
          roles: {},
        }
      );
    } else if (fromTemplate) {
      await setDoc(
        doc(db, 'user_data', selectedCompany.id, 'special_forms'),
        selectedTemplate.data()
      );
      await setDoc(doc(db, 'user_data', selectedCompany.id, 'special_entries'), {
        fieldValues: [],
        roles: {},
      });
    }

    // Add Folder to /folders
    await setDoc(
      doc(db, 'user_data', selectedCompany.id, 'folders', 'IDR', 'subfolders', newFormName),
      {
        name: newFormName,
        roles: {},
        files: [],
      }
    );
  }

  /// HTML Component ///

  return (
    <div className="component-container w-[100%] lg:w-[75%] block relative ">
      <div className="component-icon-container bg-pink-400">
        <ClipboardListIcon className="w-8 h-8" />
      </div>
      <p className="text-2xl p-1 m-1">Add IDR Form</p>
      <div className="mt-3">
        <div className="mx-1 mb-2">
          <div className="flex mb-1">
            <button
              className={
                'w-1/2 p-1 rounded-lg border border-pink-400 hover:bg-pink-400 hover:text-white mx-1 transition-all ' +
                (!fromTemplate ? ' bg-pink-400 text-white' : ' bg-white text-pink-400')
              }
              onClick={() => setFromTemplate(false)}
            >
              New
            </button>
            <button
              className={
                'w-1/2 p-1 rounded-lg border border-pink-400 hover:bg-pink-400 hover:text-white mx-1 transition-all ' +
                (fromTemplate ? ' bg-pink-400 text-white' : ' bg-white text-pink-400')
              }
              onClick={() => setFromTemplate(true)}
            >
              From Template
            </button>
          </div>
          <div
            className={
              'rounded-lg border p-1 mx-1 mb-2 scrl-none transition-all ' +
              (fromTemplate ? ' h-28 overflow-y-auto' : ' h-0 overflow-none p-0 border-none')
            }
          >
            {templates?.map((template, index) => (
              <button
                key={index}
                className={
                  'w-[100%] hover:bg-pink-400 hover:text-white p-1 rounded-lg ' +
                  (template.id === selectedTemplate?.id ? 'bg-pink-400 text-white' : '')
                }
                onClick={() => selectTemplate(template)}
              >
                {template.id}
              </button>
            ))}
          </div>
          <div className="flex">
            <div className="w-3/5 mx-1">
              <input
                className="p-1 border rounded-lg w-[100%] focus:outline-none focus:border-slate-400"
                onChange={(e) => setNewFormName(e.target.value)}
                placeholder="Insert Form Name"
              />
            </div>
            <div className="w-2/5 mx-1">
              <button
                className="p-1 border border-pink-400 hover:bg-pink-500 hover:border-pink-500 rounded-lg bg-pink-400 text-white w-[100%] transition-all hover:shadow-lg"
                onClick={addForm}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSpecialForm;
