import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';

function IDRAuthButton({ selectedCompany, entry, user, saveCall, setSaveCall, switchCall }) {
  const [entryData, setEntryData] = useState(entry.data());

  useEffect(() => {
    async function saveAuthorization() {
      await updateDoc(doc(db, 'user_data', selectedCompany.id, 'special_entries', entry.id), {
        roles: entryData.roles || {},
      });

      setSaveCall(false);
    }

    if (saveCall) {
      saveAuthorization();
    }
  }, [saveCall]);

  useEffect(() => {
    console.log(switchCall);

    if (switchCall === 'switchNeg') {
      setEntryData((prev) => {
        const entryDataCopy = { ...prev };
        const rolesCopy = { ...(entryDataCopy.roles || {}) };

        if (rolesCopy[user.id] === 'editor') {
          // Deleting if role exists
          delete rolesCopy[user.id];
        }

        entryDataCopy.roles = rolesCopy;
        return entryDataCopy;
      });
    } else if (switchCall === 'switchPos') {
      setEntryData((prev) => {
        console.log('Positive Switch');
        const entryDataCopy = { ...prev };
        const rolesCopy = { ...(entryDataCopy.roles || {}) };

        if (rolesCopy[user.id] !== 'editor') {
          rolesCopy[user.id] = 'editor';
        }

        entryDataCopy.roles = rolesCopy;
        return entryDataCopy;
      });
    }
  }, [switchCall]);

  function getAuthState() {
    if (user) {
      if (entryData?.roles?.[user.id] === 'editor') {
        return 'bg-green-400 ';
      } else {
        return 'bg-red-400 ';
      }
    } else {
      return 'bg-amber-400 ';
    }
  }

  function changeEntryAuth() {
    setEntryData((prev) => {
      const entryDataCopy = { ...prev };
      const rolesCopy = { ...(entryDataCopy.roles || {}) };

      if (rolesCopy[user.id] === 'editor') {
        // Deleting if role exists
        delete rolesCopy[user.id];
      } else {
        // Add if role does not exist
        rolesCopy[user.id] = 'editor';
      }

      entryDataCopy.roles = rolesCopy;
      return entryDataCopy;
    });
  }

  return (
    <button onClick={changeEntryAuth} className={'auth-button ' + getAuthState()}>
      {entry.id}
    </button>
  );
}

export default IDRAuthButton;
