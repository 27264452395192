import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import React from 'react';

const ChangeRowOrder = ({
  setFormData,
  changeRowsVisible,
  changeCategoryIndex,
  changeRowIndex,
}) => {
  /// Change Row Order ///

  /// Change Row Order ///

  function changeRowUp(categoryIndex, rowIndex) {
    setFormData((prev) => {
      const updatedFormData = { ...prev };
      const categoriesCopy = [...updatedFormData.categories];
      const categoriesFieldsCopy = [...categoriesCopy[categoryIndex].fields];

      if (updatedFormData.categories[categoryIndex].fields[rowIndex - 1]) {
        [categoriesFieldsCopy[rowIndex - 1], categoriesFieldsCopy[rowIndex]] = [
          categoriesFieldsCopy[rowIndex],
          categoriesFieldsCopy[rowIndex - 1],
        ];
        categoriesCopy[categoryIndex] = {
          ...categoriesCopy[categoryIndex],
          fields: categoriesFieldsCopy,
        };
        updatedFormData.categories = categoriesCopy;
      } else if (
        updatedFormData.categories[categoryIndex - 1] &&
        updatedFormData.categories[categoryIndex - 1].type === 'information'
      ) {
        categoriesCopy[categoryIndex - 1].fields.push(categoriesFieldsCopy[rowIndex]);
        categoriesFieldsCopy.splice(rowIndex, 1);
        categoriesCopy[categoryIndex] = {
          ...categoriesCopy[categoryIndex],
          fields: categoriesFieldsCopy,
        };
        updatedFormData.categories = categoriesCopy;
      }

      return updatedFormData;
    });
  }

  function changeRowDown(categoryIndex, rowIndex) {
    setFormData((prev) => {
      const updatedFormData = { ...prev };
      const categoriesCopy = [...updatedFormData.categories];
      const categoriesFieldsCopy = [...categoriesCopy[categoryIndex].fields];

      if (updatedFormData.categories[categoryIndex].fields[rowIndex + 1]) {
        [categoriesFieldsCopy[rowIndex + 1], categoriesFieldsCopy[rowIndex]] = [
          categoriesFieldsCopy[rowIndex],
          categoriesFieldsCopy[rowIndex + 1],
        ];
        categoriesCopy[categoryIndex] = {
          ...categoriesCopy[categoryIndex],
          fields: categoriesFieldsCopy,
        };
        updatedFormData.categories = categoriesCopy;
      } else if (
        updatedFormData.categories[categoryIndex + 1] &&
        updatedFormData.categories[categoryIndex + 1].type === 'information'
      ) {
        categoriesCopy[categoryIndex + 1].fields.unshift(
          categoriesCopy[categoryIndex].fields[rowIndex]
        );
        categoriesFieldsCopy.splice(rowIndex);

        categoriesCopy[categoryIndex] = {
          ...categoriesCopy[categoryIndex],
          fields: categoriesFieldsCopy,
        };
        updatedFormData.categories = categoriesCopy;
      }

      return updatedFormData;
    });
  }

  return (
    <div
      className={'inline h-1/1 margin-auto absolute pl-5' + (changeRowsVisible ? '' : ' hidden')}
    >
      <button className="p-1.5 mr-1 rounded-full bg-neutral-300 hover:scale-115 transition-all  relative hover:bottom-0.5">
        <ArrowUpIcon
          className="w-5 h-5 "
          onClick={() => changeRowUp(changeCategoryIndex, changeRowIndex)}
        />
      </button>
      <button className="p-1.5 rounded-full bg-neutral-300 hover:scale-115 transition-all  relative relative hover:top-0.5">
        <ArrowDownIcon
          className="w-5 h-5"
          onClick={() => changeRowDown(changeCategoryIndex, changeRowIndex)}
        />
      </button>
    </div>
  );
};

export default ChangeRowOrder;
