import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../firebase';

function PageNotFound() {
  const navigate = useNavigate();
  const currentUser = useAuth();

  /// HTML Component ///

  return (
    <div className="font-body py-16">
      <div className="w-[80%] lg:w-[28rem] mx-auto bg-white shadow-lg px-10 py-12 rounded-lg text-neutral-700 hover:scale-[1.005] transition-all cursor-pointer">
        <div className="rounded-full border border-2 p-2 w-max mx-auto mb-6 text-red-500 border-red-500">
          <XIcon className="w-10 h-10" />
        </div>
        <div className=" text-2xl text-center flex items-center justify-center">
          {currentUser ? 'Page not found!' : 'Page not found, please Login!'}
        </div>
        {!currentUser && (
          <div className="flex justify-center text-white text-xl mt-6">
            <button
              onClick={() => navigate('/login')}
              className="w-[100%] p-4 shadow-lg rounded-md bg-gradient-to-r from-emerald-500 to-lime-600"
            >
              Login
            </button>
          </div>
        )}
        {/* <div className='mt-5 flex justify-center text-indigo-700'>
                    <span className='flex items-center hover:ml-2 transition-all'>
                        Dashboard <ChevronRightIcon className='w-4 h-4 inline' />
                    </span>
                </div> */}
      </div>
    </div>
  );
}

export default PageNotFound;
