import {
  AnnotationIcon,
  BadgeCheckIcon,
  ChartBarIcon,
  ChatAlt2Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardCheckIcon,
  ClipboardCopyIcon,
  CogIcon,
  DocumentAddIcon,
  DocumentReportIcon,
  DocumentTextIcon,
  DownloadIcon,
  FolderIcon,
  LocationMarkerIcon,
  LoginIcon,
  LogoutIcon,
  MailIcon,
  MenuIcon,
  OfficeBuildingIcon,
  PencilAltIcon,
  PencilIcon,
  PresentationChartBarIcon,
  RefreshIcon,
  TableIcon,
  UserAddIcon,
} from '@heroicons/react/outline';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, logout, useAuth } from '../firebase';
import taxmindLogo from '../assets/Taxmind Logo.webp';
import tpxLogo from '../assets/tpx-logo.png';

function Navbar({
  setSingleUser,
  superUser,
  setSuperUser,
  masterUser,
  setMasterUser,
  clientManager,
  setClientManager,
  selectedCompany,
  setSelectedCompany,
}) {
  const navigate = useNavigate();
  const currentUser = useAuth();

  const [companiesVisible, setCompaniesVisible] = useState();
  const [companies, setCompanies] = useState();

  const [settingsVisible, setSettingsVisible] = useState();

  function handleSettingsVisibility() {
    if (settingsVisible) {
      setSettingsVisible(false);
    } else {
      setSettingsVisible(true);
    }
  }

  // check if admin -> give selection opportunity / list companies
  // check where user has auth rights -> set company

  // check where user has auth rights
  // user has one -> selectedCompany
  // user has none -> look for all companies

  /// Loading available Clients & Fetching Auth Rights ///

  useEffect(() => {
    // check if user is logged in
    if (!currentUser) {
      return;
    }

    setCompanies();
    setSuperUser(false);
    setMasterUser(false);
    setClientManager(false);
    setSelectedCompany();

    async function fetchAuthRights() {
      const authCompanies = await getDocs(
        query(collection(db, 'user_data'), where(`roles.${currentUser?.uid}`, '==', 'editor'))
      );
      const superAuthCompanies = await getDocs(
        query(collection(db, 'user_data'), where(`roles.${currentUser?.uid}`, '==', 'super-user'))
      );
      const clientManagerCompanies = await getDocs(
        query(
          collection(db, 'user_data'),
          where(`roles.${currentUser?.uid}`, '==', 'client-manager')
        )
      );

      if (authCompanies.docs.length === 1) {
        console.log('editor present.');
        setSingleUser(true);
        setSelectedCompany(authCompanies.docs[0]);
      } else if (superAuthCompanies.docs.length === 1) {
        console.log('super user present.');
        setSuperUser(true);
        setSelectedCompany(superAuthCompanies.docs[0]);
      } else if (clientManagerCompanies.docs.length === 1) {
        console.log('client manager present.');
        setClientManager(true);
        setSelectedCompany(clientManagerCompanies.docs[0]);
      } else {
        console.log('master user present.');
        const newCompanies = await getDocs(collection(db, 'user_data'));

        setMasterUser(true);
        setCompanies(newCompanies.docs);
        setSelectedCompany(JSON.parse(localStorage.getItem('selectedCompany')));
      }
    }

    fetchAuthRights();
  }, [currentUser]);

  const editingRef = useRef();
  const analyticsRef = useRef();
  const idrRef = useRef();
  const emailRef = useRef();
  const taxmindRef = useRef();
  const companiesRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdownPointer =
        !editingRef.current?.contains(event.target) &&
        !analyticsRef.current?.contains(event.target) &&
        !idrRef.current?.contains(event.target) &&
        !emailRef.current?.contains(event.target) &&
        !taxmindRef.current?.contains(event.target) &&
        !companiesRef.current?.contains(event.target);
      if (dropdownPointer) {
        setActiveMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // list new company if created !Auslagern! -> evtl. Möglichkeit Calls zu sparen

  /// Client Selection ///

  function selectCompany(company) {
    setCompaniesVisible(false);
    setSelectedCompany(company);
  }

  /// Reloading Clients ///

  useEffect(() => {
    // check if user is logged in
    if (!currentUser) {
      return;
    }

    async function fetchCompanies() {
      const newCompanies = await getDocs(collection(db, 'user_data'));
      setCompanies(newCompanies.docs);
    }

    fetchCompanies();
  }, [selectedCompany]);

  /// Saving Selected Client in Local Storage ///

  useEffect(() => {
    if (selectedCompany) {
      localStorage.setItem('selectedCompany', JSON.stringify({ id: selectedCompany?.id }));
    }
  }, [selectedCompany]);

  const selectedMenuStyle = ' border-2 py-[6px] px-[8px]';

  // Black Backgroud:     bg-neutral-700 text-white hover:text-black
  // Underlined:          underline

  const [activeMenu, setActiveMenu] = useState();
  const [selectedMenu, setSelectedMenu] = useState();

  const [sideBarVisible, setSideBarVisible] = useState(false);

  /// HTML Component ///

  return (
    <>
      <div
        onClick={() => setSideBarVisible(false)}
        className={
          'bg-black/50 fixed h-screen w-screen z-[100] ' + (sideBarVisible ? '' : 'hidden')
        }
      ></div>
      <div className="w-[100%] p-3 bg-white border-b border-neutral-50 inter-500 text-black z-[100] flex justify-between font-body fixed top-0">
        <div className="flex items-center w-[100%] ">
          {/* Logo and Admin Menu */}

          <div ref={taxmindRef} className="ml-2 mr-3 shrink-0 ">
            <div className="w-auto mx-auto px-4">
              <img
                onClick={() => setActiveMenu((prev) => (prev === 'Master' ? undefined : 'Master'))}
                className="h-11 cursor-pointer w-auto"
                src={tpxLogo}
              />
            </div>
            <div
              className={
                'nav-container w-[18rem] mt-[18px] ' +
                (activeMenu === 'Master' && (masterUser || clientManager) ? '' : 'hidden')
              }
            >
              {' '}
              {masterUser && (
                <button
                  onClick={() => {
                    navigate('/manage-companies');
                    setActiveMenu();
                    setSelectedMenu();
                  }}
                  className="nav-btn"
                >
                  <OfficeBuildingIcon className="nav-icon" /> Clients
                </button>
              )}
              <button
                onClick={() => {
                  navigate('/manage-users');
                  setActiveMenu();
                  setSelectedMenu();
                }}
                className="nav-btn"
              >
                <UserAddIcon className="nav-icon" /> Users
              </button>
              <button
                onClick={() => {
                  navigate('/edit-template');
                  setActiveMenu();
                  setSelectedMenu();
                }}
                className="nav-btn"
              >
                <DocumentTextIcon className="nav-icon" /> Edit Form Template
              </button>
              <button
                onClick={() => {
                  navigate('/edit-form');
                  setActiveMenu();
                  setSelectedMenu();
                }}
                className="nav-btn"
              >
                <PencilAltIcon className="nav-icon" /> Edit Form
              </button>
              <button
                onClick={() => {
                  navigate('/manage-lf-templates');
                  setActiveMenu();
                  setSelectedMenu();
                }}
                className="nav-btn"
              >
                <DocumentAddIcon className="nav-icon" /> Manage Topic Templates
              </button>
              <button
                onClick={() => {
                  navigate('/import-csv');
                  setActiveMenu();
                  setSelectedMenu();
                }}
                className="nav-btn"
              >
                <DownloadIcon className="nav-icon" /> Import Data
              </button>
            </div>
          </div>

          {/* Client Display */}

          {currentUser && (
            <div ref={companiesRef}>
              <button
                className="py-2 px-5 rounded-lg bg-slate-100 mx-2 whitespace-nowrap"
                onClick={() =>
                  setActiveMenu((prev) => (prev === 'Clients' ? undefined : 'Clients'))
                }
              >
                {selectedCompany?.id ? selectedCompany?.id : 'Client'}
              </button>
              {companies?.length > 0 && (
                <div className={'nav-container w-48' + (activeMenu === 'Clients' ? '' : ' hidden')}>
                  {companies?.map((company, index) => (
                    <button
                      onClick={() => {
                        selectCompany(company);
                        setActiveMenu();
                      }}
                      className="nav-btn"
                    >
                      <OfficeBuildingIcon className="nav-icon" />
                      {company.id}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="ml-auto mr-0 md:hidden">
            <button className="" onClick={() => setSideBarVisible(true)}>
              <MenuIcon className="w-8 h-8" />
            </button>
          </div>

          <div
            className={
              'flex flex-col bg-white md:flex-row md:justify-between absolute md:static w-[20rem] h-screen md:h-auto md:w-[100%] top-0 right-0 z-[60] transition-all' +
              (sideBarVisible ? '' : ' -mr-[100%] md:-mr-0')
            }
          >
            {/* Navigation Menu */}

            <div className="flex flex-col md:flex-row mt-16 md:mt-0">
              {currentUser && (
                <>
                  <div
                    ref={editingRef}
                    className="mb-8 md:mb-0 flex flex-col items-center md:block relative z-[5] mx-2"
                  >
                    <button
                      onClick={() => {
                        navigate('/select-client');
                        setSelectedMenu('Editing');
                      }}
                      className={
                        'p-2 px-4 md:rounded-lg hover:bg-slate-100 transition-all w-[100%] md:w-auto' +
                        (selectedMenu === 'Editing' && selectedMenuStyle)
                      }
                    >
                      Edit Document
                    </button>
                  </div>

                  <div className="mb-8 md:mb-0 flex flex-col items-center md:block relative z-[5] mx-2">
                    <button
                      onClick={() => {
                        navigate('/create-localfile');
                        setSelectedMenu('Export');
                      }}
                      className={
                        'p-2 px-4 md:rounded-lg hover:bg-slate-100 transition-all w-[100%] md:w-auto' +
                        (selectedMenu === 'Export' && selectedMenuStyle)
                      }
                    >
                      Export Document
                    </button>
                  </div>

                  <div className="mb-8 md:mb-0 flex flex-col items-center md:block relative z-[4] mx-2">
                    <button
                      onClick={() => {
                        navigate('/files');
                        setActiveMenu();
                        setSelectedMenu('Files');
                      }}
                      className={
                        'p-2 px-4 md:rounded-lg hover:bg-slate-100 transition-all w-[100%] md:w-auto ' +
                        (selectedMenu === 'Files' && selectedMenuStyle)
                      }
                    >
                      Files
                    </button>
                  </div>

                  <div
                    ref={analyticsRef}
                    className="mb-8 md:mb-0 flex flex-col items-center md:block relative z-[3] mx-2"
                  >
                    <button
                      onClick={() =>
                        setActiveMenu((prev) => (prev === 'Analytics' ? undefined : 'Analytics'))
                      }
                      className={
                        'p-2 px-4 md:rounded-lg hover:bg-slate-100 transition-all w-[100%] md:w-auto ' +
                        (selectedMenu === 'Analytics' && selectedMenuStyle)
                      }
                    >
                      Analytics
                    </button>
                    <div
                      className={
                        'nav-container mt-10 md:mt-3 md:w-[14rem] left-0 md:left-auto ' +
                        (activeMenu === 'Analytics' ? '' : 'hidden')
                      }
                    >
                      <button
                        onClick={() => {
                          navigate('/create-csv');
                          setActiveMenu();
                          setSelectedMenu('Analytics');
                        }}
                        className="nav-btn"
                      >
                        <PresentationChartBarIcon className="nav-icon" /> Data Analytics
                      </button>
                      <button
                        onClick={() => {
                          navigate('/create-project-csv');
                          setActiveMenu();
                          setSelectedMenu('Analytics');
                        }}
                        className="nav-btn"
                      >
                        <ChatAlt2Icon className="nav-icon" /> Project CSV
                      </button>
                      <button
                        onClick={() => {
                          navigate('/create-status-csv');
                          setActiveMenu();
                          setSelectedMenu('Analytics');
                        }}
                        className="nav-btn"
                      >
                        <ClipboardCheckIcon className="nav-icon" /> Status CSV
                      </button>
                    </div>
                  </div>
                  {(masterUser || superUser || clientManager) && (
                    <div className="mb-8 md:mb-0 flex flex-col items-center md:block relative z-[2] mx-2">
                      <button
                        onClick={() => {
                          navigate('/workflow');
                          setActiveMenu();
                          setSelectedMenu('Workflow');
                        }}
                        className={
                          'p-2 px-4 md:rounded-lg hover:bg-slate-100 transition-all w-[100%] md:w-auto ' +
                          (selectedMenu === 'Workflow' && selectedMenuStyle)
                        }
                      >
                        Workflow
                      </button>
                    </div>
                  )}

                  {/* <div className="py-2 w-[5px] h-[80%] mx-2 border-r my-auto hidden lg:block"></div>
                  <div ref={idrRef} className="mb-8 md:mb-0 flex flex-col items-center md:block relative z-[2]">
                    <button
                      onClick={() => setActiveMenu((prev) => (prev === "IDR" ? undefined : "IDR"))}
                      className={"p-2 px-4 md:rounded-lg hover:bg-slate-100 mx-2 transition-all w-[100%] md:w-auto " + (selectedMenu === "IDR" && selectedMenuStyle)}
                    >
                      IDR
                    </button>
                    <div className={"nav-container mt-10 md:mt-3 md:w-[16rem] left-0 md:left-auto " + (activeMenu === "IDR" ? "" : "hidden")}>
                      <div className="text-center py-1.5 rounded-lg bg-neutral-200 mb-0.5 text-xs">Information & Data Requests</div>
                      <button
                        onClick={() => {
                          navigate("/select-form");
                          setActiveMenu();
                          setSelectedMenu("IDR");
                        }}
                        className="nav-btn"
                      >
                        <ClipboardCopyIcon className="nav-icon" />
                        Select Form
                      </button>
                      <button
                        onClick={() => {
                          navigate("/create-document");
                          setActiveMenu();
                          setSelectedMenu("IDR");
                        }}
                        className="nav-btn"
                      >
                        <DocumentReportIcon className="nav-icon" />
                        Create Document
                      </button>
                      <button
                        onClick={() => {
                          navigate("/create-content-csv");
                          setActiveMenu();
                          setSelectedMenu("IDR");
                        }}
                        className="nav-btn"
                      >
                        <TableIcon className="nav-icon" />
                        Create Content CSV
                      </button>
                    </div>
                  </div>

                  {(superUser || masterUser || clientManager) && (
                    <>
                      <div className="py-2 w-[5px] h-[80%] mx-2 border-l my-auto hidden lg:block"></div>
                      <div ref={emailRef} className="mb-8 md:mb-0 flex flex-col items-center md:block relative z-[2]">
                        <button
                          onClick={() => setActiveMenu((prev) => (prev === "Mail" ? undefined : "Mail"))}
                          className={"p-2 px-4 md:rounded-lg hover:bg-slate-100 transition-all w-[100%] md:w-auto " + (selectedMenu === "Workflow" && selectedMenuStyle)}
                        >
                          Email
                        </button>
                        <div className={"nav-container mt-10 md:mt-3 md:w-[16rem] left-0 md:left-auto " + (activeMenu === "Mail" ? "" : "hidden")}>
                          <button
                            onClick={() => {
                              navigate("/edit-email-template");
                              setActiveMenu();
                              setSelectedMenu("Mail");
                            }}
                            className="nav-btn"
                          >
                            <PencilIcon className="nav-icon" />
                            Edit Email Template
                          </button>
                          <button
                            onClick={() => {
                              navigate("/notify-staff");
                              setActiveMenu();
                              setSelectedMenu("Mail");
                            }}
                            className="nav-btn"
                          >
                            <MailIcon className="nav-icon" />
                            Manage Emails
                          </button>
                        </div>
                      </div>
                    </>
                  )} */}
                </>
              )}
            </div>

            {/* Login and Logout */}

            <div className="flex flex-col md:flex-row">
              <div className="mb-8 md:mb-0 flex flex-col items-center md:block relative ">
                <button
                  onClick={() => setActiveMenu((prev) => (prev === 'Help' ? undefined : 'Help'))}
                  className="p-2 px-4 md:rounded-lg hover:bg-slate-100 mx-2 text-indigo-600 w-[100%] md:w-[6rem]"
                >
                  Help
                </button>
                <div
                  className={
                    'nav-container left-0 mt-10 md:mt-3 ' + (activeMenu === 'Help' ? '' : 'hidden')
                  }
                >
                  <button
                    onClick={() => {
                      navigate('/contact-us');
                      setActiveMenu();
                    }}
                    className="nav-btn"
                  >
                    <AnnotationIcon className="nav-icon" /> Contact Us
                  </button>
                  <button
                    onClick={() => {
                      navigate('/reset-password');
                      setActiveMenu();
                    }}
                    className="nav-btn"
                  >
                    <RefreshIcon className="nav-icon" /> Reset Password
                  </button>
                </div>
              </div>

              {currentUser ? (
                <div className="flex items-center md:block">
                  <button
                    className="py-2 px-3 rounded-lg text-red-500 mx-2 mb-8 md:mb-0 w-[100%] md:w-auto"
                    onClick={logout}
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div className="flex items-center md:block">
                  <button
                    onClick={() => navigate('/login')}
                    className="py-2 px-3 rounded-lg text-green-500 mx-2 mb-8 w-[100%] md:w-auto"
                  >
                    Login
                  </button>
                </div>
              )}
            </div>

            <button
              onClick={() => setSideBarVisible(false)}
              className="w-[50%] flex justify-center mt-auto mb-10 hover:bg-neutral-200 py-2 md:hidden rounded-lg mx-auto"
            >
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
