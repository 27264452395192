import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { getDownloadURL, getStorage, listAll, ref, uploadBytes } from 'firebase/storage';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  FieldValue,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import Papa from 'papaparse';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { getFunctions, httpsCallable } from 'firebase/functions';

/// Initializing App ///

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
const storage = getStorage(app);
const db = getFirestore(app);
const functions = getFunctions(app, 'europe-west1');

/// Fetching Current User Hook ///

export function useAuth() {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
    return unsub;
  }, []);

  return currentUser;
}

export function useFetchYears(selectedCompany) {
  const [years, setYears] = useState([]);

  useEffect(() => {
    async function fetchYears() {
      const fetchedYears = await getDocs(collection(db, 'user_data', selectedCompany.id, 'years'));
      setYears(fetchedYears.docs);
    }

    fetchYears();
  }, [selectedCompany]);

  return years;
}

export function useOutsideClick(reference, callback) {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (reference.current && !reference.current.contains(event.target)) {
        callback(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [reference, callback]);
}

/// Getting Date ///

export function getDateString() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  return yyyy + mm + dd;
}

/// Change Auth Status (Login & Logout) ///

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  return signOut(auth);
}

/// Create User ///
export async function createUser(email, password, name) {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await updateProfile(userCredential.user, {
    displayName: name,
  });
  return userCredential;
}

/// Saving Local File Templates ///

export async function saveTemplate(path, file, fileName) {
  const idSnippet = Date.now() + Math.random();

  const fileRef = ref(storage, idSnippet + '.docx');
  const snapshot = await uploadBytes(fileRef, file);
  const photoURL = await getDownloadURL(fileRef);

  await addDoc(path, {
    name: fileName,
    url: photoURL,
    storageId: idSnippet + '.docx',
  });
}

/// Creating Local File ///

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

export async function generateSerialLetter(template, data, company, year, subsidiary) {
  const templateURL = template.data().url;
  const isLocalfile = template.data().name.includes('Localfile');

  loadFile(templateURL, async function (error, content) {
    var zip = new PizZip(content);

    var localfile = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });
    localfile.setData(data);
    localfile.render();

    var out = localfile.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    saveAs(out, 'output1.docx');

    const currentDate = getDateString();
    var folder = await getDoc(
      doc(db, 'user_data', company, 'folders', year, 'subfolders', subsidiary)
    );

    // Normally, folders should already exist -> if not they get created here:
    if (!folder.data()) {
      await setDoc(doc(db, 'user_data', company, 'folders', year, 'subfolders', subsidiary), {
        files: [],
        name: subsidiary,
        roles: {},
      });
      folder = await getDoc(
        doc(db, 'user_data', company, 'folders', year, 'subfolders', subsidiary)
      );
    }

    var fileName = subsidiary + '_' + year + '_' + currentDate;
    if (isLocalfile) {
      fileName = 'Localfile_' + fileName;
    }

    if (data['Status'] !== 'Draft') {
      const path = company + '/' + fileName + '_Final' + '.docx';
      const fileRef = ref(storage, path);
      const snapshot = await uploadBytes(fileRef, out);
      const fileURL = await getDownloadURL(fileRef);

      const files = folder.data().files;

      fileName += '_Final' + '.docx';
      const fileIndex = folder.data().files.find((file) => file.name === fileName);

      if (fileIndex) {
        files[fileIndex] = { name: fileName, url: fileURL };
      } else {
        files.push({ name: fileName, url: fileURL });
      }

      await updateDoc(doc(db, 'user_data', company, 'folders', year, 'subfolders', subsidiary), {
        files,
      });
      return;
    }

    for (const number of [...Array(5).keys()]) {
      const workingNumber = number + 1;
      var finalFileName = fileName + '_Draft' + '_v' + workingNumber + '.docx';

      if (
        !folder
          .data()
          .files.map((file) => file.name)
          .includes(finalFileName)
      ) {
        const path = company + '/' + finalFileName;
        const fileRef = ref(storage, path);
        const snapshot = await uploadBytes(fileRef, out);
        const fileURL = await getDownloadURL(fileRef);

        await updateDoc(doc(db, 'user_data', company, 'folders', year, 'subfolders', subsidiary), {
          files: arrayUnion({ name: finalFileName, url: fileURL }),
        });
        return;
      }
    }
  });
}

/// Create Document ///

export async function generateIDRDocument(templateURL, data, company, form) {
  loadFile(templateURL, async function (error, content) {
    var zip = new PizZip(content);

    var idrDoc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });
    idrDoc.setData(data);

    console.log('Doc:');
    console.log(idrDoc);
    idrDoc.render();

    var out = idrDoc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    saveAs(out, 'output1.docx');

    const currentDate = getDateString();

    const folder = await getDoc(
      doc(db, 'user_data', company, 'folders', 'IDR', 'subfolders', form)
    );

    // listAll(filesRef)

    console.log(data['Status']);

    if (data['Status'] !== 'Draft') {
      const path =
        company + '/IDR' + '/DocumentIDR_' + form + '_' + currentDate + '_Final' + '.docx';
      const fileRef = ref(storage, path);
      const snapshot = await uploadBytes(fileRef, out);
      const fileURL = await getDownloadURL(fileRef);

      await updateDoc(doc(db, 'user_data', company, 'folders', 'IDR', 'subfolders', form), {
        files: arrayUnion({
          name: 'DocumentIDR_' + form + '_' + currentDate + '_Final' + '.docx',
          url: fileURL,
        }),
      });
      return;
    }

    for (const number of [...Array(5).keys()]) {
      const workingNumber = number + 1;
      const fileName =
        'DocumentIDR_' + form + '_' + currentDate + '_Draft' + '_v' + workingNumber + '.docx';

      if (
        !folder
          .data()
          .files.map((file) => file.name)
          .includes(fileName)
      ) {
        const path =
          company +
          '/IDR' +
          '/DocumentIDR_' +
          form +
          '_' +
          currentDate +
          '_Draft_v' +
          workingNumber +
          '.docx';
        const fileRef = ref(storage, path);
        const snapshot = await uploadBytes(fileRef, out);
        const fileURL = await getDownloadURL(fileRef);

        console.log(fileName);

        await updateDoc(doc(db, 'user_data', company, 'folders', 'IDR', 'subfolders', form), {
          files: arrayUnion({ name: fileName, url: fileURL }),
        });
        return;
      }
    }
  });
}

/// Get Client Status ///

export async function getClientStatus(company, year, subsidiary, subsidiaryData, setStatus) {
  var statusCache = 'Final';

  const formFrame = await getDoc(
    doc(db, 'user_data', company, 'years', year, 'forms', 'standard_form')
  );

  for (const category of formFrame.data().categories) {
    for (const field of category.fields) {
      const matchField = subsidiaryData.fieldValues.find(
        (fieldValue) => fieldValue.id === field.id
      );
      if (!matchField?.done && statusCache !== 'Draft') {
        console.log(matchField);
        console.log(year);
        statusCache = 'Draft';
      }
    }
  }

  console.log(statusCache);
  setStatus(statusCache);
}

export async function getIDRStatus(company, form, entryData, setStatus) {
  var statusCache = 'Final';

  const formFrame = await getDoc(doc(db, 'user_data', company, 'special_forms', form));

  for (const category of formFrame.data().categories) {
    for (const field of category.fields) {
      const matchField = entryData.fieldValues.find((fieldValue) => fieldValue.id === field.id);
      if (!matchField?.done && statusCache !== 'Draft') {
        console.log(matchField);
        statusCache = 'Draft';
      }
    }
  }

  console.log(statusCache);
  setStatus(statusCache);
}

/// Shorting Strings ///

export function shortify(entryString, maxLength) {
  if (entryString?.length > 8) {
    return entryString.slice(0, maxLength).concat('...');
  } else {
    return entryString;
  }
}

/// State Animations ///

export function animateButton(stateFunction, speed) {
  stateFunction((prevState) => !prevState);
  setTimeout(() => stateFunction((prevState) => !prevState), speed);
}

export { auth, db, storage, functions };
