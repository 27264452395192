import { InformationCircleIcon } from '@heroicons/react/outline';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useRef } from 'react';
import { auth } from '../../firebase';

function ForgotPasswordForm(props) {
  const emailRef = useRef();

  /// Sending Password Reset Email  ///

  async function sendResetEmail() {
    console.log('sending email');

    console.log(emailRef.current.value);
    await sendPasswordResetEmail(auth, emailRef.current.value);
    alert('Password-Reset Email sent.');
  }

  /// HTML Component ///

  return (
    <div className="bg-white p-6 rounded-xl shadow-around w-[100%] h-max z-[100]">
      <p className="text-2xl text-center">{props.title}</p>
      <p className="mt-3 text-center mb-0.5 border rounded-lg p-2 text-lg">
        <p className="my-0.5">
          <InformationCircleIcon className="w-6 h-6 mx-auto" />
        </p>
        An email will be sent to you, if it hasn't arrived within 5 minutes, send again. Please also
        check your spam.
      </p>
      <input
        ref={emailRef}
        className="border rounded-lg p-1 focus:outline-none text-lg mb-0.5 w-[100%]"
        placeholder="Email"
      />
      <button className="block text-white bg-blue-500 p-2 w-[100%]" onClick={sendResetEmail}>
        Send Reset Email
      </button>
    </div>
  );
}

export default ForgotPasswordForm;
