import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';

function SuperAuthButton({
  selectedCompanyData,
  setSelectedCompanyData,
  rolesObjName,
  user,
  expectedRole,
  roleDesc,
}) {
  const [changed, setChanged] = useState(false);

  /// Handling Auth Changes ///

  function changeAuthorization() {
    const updatedCompany = { ...selectedCompanyData };

    if (!changed) {
      setChanged(true);
    } else {
      setChanged(false);
    }

    if (updatedCompany[rolesObjName][user.id] === expectedRole) {
      delete updatedCompany[rolesObjName][user.id];
    } else {
      updatedCompany[rolesObjName][user.id] = expectedRole;
    }

    console.log(updatedCompany);
    setSelectedCompanyData(updatedCompany);
  }

  /// Handling Auth Changes ///

  function getAuthState() {
    if (selectedCompanyData && user.id) {
      if (selectedCompanyData?.[rolesObjName]?.[user.id] === expectedRole) {
        return 'bg-green-400 ';
      } else {
        return 'bg-red-400 ';
      }
    } else {
      return 'bg-amber-400 ';
    }
  }

  /// HTML Component ///

  return (
    <button className={getAuthState() + 'auth-button'} onClick={changeAuthorization}>
      {roleDesc}
    </button>
  );
}

export default SuperAuthButton;
