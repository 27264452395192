import { AnnotationIcon } from '@heroicons/react/outline';
import React, { useEffect } from 'react';

function ContactUs() {
  /// Title Specification ///

  useEffect(() => {
    document.title = 'Contact Us - Contact Information for Requests';
  }, []);

  /// HTML Component ///

  return (
    <div className="w-[560px] rounded-lg shadow-lg bg-white font-body mx-auto my-20">
      <div className="p-4">
        <div className="component-icon-container bg-sky-400">
          <AnnotationIcon className="w-8 h-8" />
        </div>
        <p className="text-2xl p-1 m-1">Contact Us</p>
        <div className="mt-3 p-3 text-lg border rounded-lg m-2 hover:bg-slate-300">
          <p className="text-xl mb-1">TAXMIND GmbH Steuerberatungsgesellschaft</p>
          <p>Alfred-Herrhausen-Allee 3-5</p>
          <p>65760 Frankfurt-Eschborn</p>
          <br></br>
          <p>
            E-Mail:{' '}
            <a
              className="hover:bg-sky-300 hover:text-white transition"
              href="mailto:info@tax-mind.com"
            >
              info@tax-mind.com
            </a>
          </p>
          <p>
            Tel.:{' '}
            <a className="hover:bg-sky-300 hover:text-white transition" href="tel:+490619658655660">
              +49 (0) 6196 58 655 660
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
