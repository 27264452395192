import React, { useCallback, useEffect, useRef, useState } from 'react';
import { animateButton, db, updateData, uploadData, useAuth } from '../../firebase';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import {
  CheckIcon,
  ClipboardCopyIcon,
  DuplicateIcon,
  PlusIcon,
  SaveIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useBeforeunload } from 'react-beforeunload';
import InfoMainInput from './InfoMainInput';
import TransactionPartnerInput from './TransactionPartnerInput';
import NumbersInput from './NumbersInput';
import TransactionAmountInput from './TransactionAmountInput';
import SelectionMenu from './SelectionMenu';
import FormFields from './FormFields';

function SelectForm({ selectedCompany, masterUser }) {
  const [entries, setEntries] = useState([]);
  const [selectedForm, setSelectedForm] = useState();

  const [formData, setFormData] = useState();
  const [userData, setUserData] = useState({});

  const [formsVisible, setFormsVisible] = useState(false);

  const currentUser = useAuth();

  const [superEditor, setSuperEditor] = useState(false);

  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [saveAnimation, setSaveAnimation] = useState(false);

  /// Title Specification ///

  useEffect(() => {
    document.title = 'Select Form - Enter your Data securely';
  }, []);

  /// Preventing Refresh ///

  useBeforeunload(() => 'Are you sure to close this tab?');

  /// Loading Data & Handling Data Selection ///

  useEffect(() => {
    async function fetchPermissions() {
      const selectedCompanyCache = await getDoc(doc(db, 'user_data', selectedCompany.id));
      setSuperEditor(selectedCompanyCache.data().superEditing?.[currentUser?.uid]);
    }

    setFormData();
    setSelectedForm();

    async function fetchForms() {
      const user = 'roles.' + currentUser.uid;

      try {
        const fetchedEntries = await getDocs(
          query(collection(db, 'user_data', selectedCompany.id, 'special_entries'))
        );
        setEntries(fetchedEntries.docs);
      } catch {
        const fetchedEntries = await getDocs(
          query(
            collection(db, 'user_data', selectedCompany.id, 'special_entries'),
            where(user, '==', 'editor')
          )
        );
        setEntries(fetchedEntries.docs);
      }
    }

    if (selectedCompany?.id && currentUser) {
      fetchPermissions();
      fetchForms();
    }
  }, [selectedCompany, currentUser]);

  /// Document Selection ///

  async function handleSetData(entry) {
    setSelectedForm(entry);

    // const fetchedEntries = await getDoc(doc(db, "user_data", selectedCompany.id, "special_entries", form.id))
    setUserData(entry.data());

    const fetchedForm = await getDoc(
      doc(db, 'user_data', selectedCompany.id, 'special_forms', entry.id)
    );
    setFormData(fetchedForm.data());

    setFormsVisible(false);
  }

  /// Saving user data changes ///

  async function handleSaveChanges() {
    setSaveIsLoading(true);

    await updateDoc(doc(db, 'user_data', selectedCompany.id, 'special_entries', selectedForm.id), {
      fieldValues: userData.fieldValues,
    });

    animateButton(setSaveAnimation, 2000);
    setSaveIsLoading(false);
  }

  /// HTML Component ///

  return (
    <>
      <div
        className={
          ' font-body p-0 my-0 mx-auto bg-white flex flex-col relative ' + (formData ? '' : '')
        }
      >
        <div className="flex justify-between items-center fixed z-20 w-[100%]">
          <div className="bg-white text-lg px-2 py-1 flex items-center w-[100%]">
            <div className="flex items-center p-2">
              <div className="p-2 bg-blue-500 text-white rounded-full mr-4">
                <ClipboardCopyIcon className="w-7 h-7" />
              </div>
              <span className="text-lg">Select Form</span>
              {/* <span className='underline'>I</span>nformation & <span className='underline'>D</span>ata <span className='underline'>R</span>equest */}
            </div>
            <div className="ml-8 relative">
              <SelectionMenu
                options={entries}
                selectedOption={selectedForm}
                handleSelect={handleSetData}
                placeholder={'Form'}
                optionsVisible={formsVisible}
                setOptionsVisible={setFormsVisible}
              />
            </div>

            {/* <div className='ml-4 relative z-[150]'>
                            <SelectionMenu 
                                options={subsidiaries}
                                selectedOption={selectedSubsidiary}
                                handleSelect={handleSetSubData}
                                placeholder={"Document"}
                                optionsVisible={subsidiariesVisible}
                                setOptionsVisible={setSubsidiariesVisible}
                            />
                        </div> */}
          </div>
          {selectedForm?.id && (
            <>
              <div className="flex justify-center items-center my-6 relative">
                <button
                  className="overflow-hidden absolute w-36 right-10 bg-blue-500 text-white hover:shadow-lg rounded-lg text-lg transition-all "
                  onClick={handleSaveChanges}
                >
                  <div
                    className={
                      'bg-green-500 rounded-lg absolute px-2.5 py-1.5 w-[100%] text-white transition-all flex items-center justify-center ' +
                      (saveAnimation ? 'left-0' : 'left-[100%]')
                    }
                  >
                    <CheckIcon className="w-6 h-6 inline mr-1" />
                    Saved{' '}
                  </div>
                  <div className="py-1.5 px-2.5">
                    <span>Save Entries</span>
                  </div>
                </button>
              </div>
            </>
          )}
        </div>

        {/* Table Header */}

        {formData && (
          <div className="w-[100%] my-[2px] text-lg py-1.5 border-b bg-slate-300 fixed z-10 mt-[68px]">
            <div className="w-[95%] px-5 mx-auto flex">
              <div className="w-[23%] flex justify-center items-center">Topic</div>
              <div className="w-[23%] flex justify-center items-center">Description</div>
              <div className="w-[23%] flex justify-center items-center">Enter in LF-Language</div>
              <div className="w-[23%] flex justify-center items-center">Remarks</div>
              <div className="w-[8%] flex justify-center items-center">
                <CheckIcon className="w-6 h-6" /> (final)
              </div>
            </div>
          </div>
        )}

        {/* Table Rows */}
        <FormFields
          userData={userData}
          setUserData={setUserData}
          formData={formData}
          selectedSubsidiary={selectedForm}
          superEditor={superEditor}
          masterUser={masterUser}
        />
      </div>
    </>
  );
}

export default SelectForm;
