import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline';
import React, { useEffect, useRef } from 'react';

const AdditionalPropsMenu = React.memo(({ field, i, j, handleClickOutside, setFormData }) => {
  const menuRef = useRef();

  useEffect(() => {
    // This uses the 'mousedown' event in order to prevent a collison with the remove answer option

    document.addEventListener('mousedown', (e) => handleClickOutside(e, menuRef));

    return () => {
      document.removeEventListener('mousedown', (e) => handleClickOutside(e, menuRef));
    };
  }, []);

  function handlePlaceholderChange(e, categoryIndex, rowNumber) {
    setFormData((prev) => {
      const updatedFormData = { ...prev };

      updatedFormData.categories[categoryIndex].fields[rowNumber].placeholder = e.target.value;

      return updatedFormData;
    });
  }

  function addAnswerOpt(categoryIndex, rowNumber) {
    setFormData((prev) => {
      const updatedFormData = { ...prev };

      if (!updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities) {
        updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities = [
          { optName: '' },
        ];
      } else {
        updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities.push({
          optName: '',
        });
      }

      return updatedFormData;
    });
  }

  function deleteAnswerOpt(categoryIndex, rowNumber, optIndex) {
    setFormData((prev) => {
      const updatedFormData = { ...prev };

      updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities.splice(
        optIndex,
        1
      );

      return updatedFormData;
    });
  }

  function handleAnswerOptChange(e, categoryIndex, rowNumber, optIndex) {
    setFormData((prev) => {
      const updatedFormData = { ...prev };

      updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities[
        optIndex
      ].opt = e.target.value;

      return updatedFormData;
    });
  }

  function handleAnswerOptColorChange(color, categoryIndex, rowNumber, optIndex) {
    setFormData((prev) => {
      const updatedFormData = { ...prev };

      if (
        typeof updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities[
          optIndex
        ] === 'string' &&
        color
      ) {
        updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities[optIndex] =
          {
            opt: updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities[
              optIndex
            ],
            color: color,
          };
      } else {
        if (color) {
          updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities[
            optIndex
          ].color = color;
        } else {
          delete updatedFormData.categories[categoryIndex].fields[rowNumber].answer_possibilities[
            optIndex
          ].color;
        }
      }

      return updatedFormData;
    });
  }

  return (
    <div
      ref={menuRef}
      className="fixed mt-[195px] w-[500px] top-0 left-0 right-0 mx-auto bg-slate-100 shadow-lg rounded-lg px-2 z-[20] text-center transition-all text-lg"
    >
      <p className="py-4 text-xl underline text-bold">Additional Properties</p>
      <div className="">
        <label
          htmlFor={field.id + '-placeholder'}
          className="block py-2 px-1 rounded-lg bg-white hover:bg-blue-500 hover:text-white w-[100%] mt-2 mb-1"
        >
          Add Placeholder
        </label>
        <input
          type="checkbox"
          id={field.id + '-placeholder'}
          className="hidden visibility-checkbox"
        />
        <div className="visibility-item transition-all">
          <textarea
            value={field.placeholder}
            onChange={(e) => handlePlaceholderChange(e, i, j)}
            className="w-[100%] focus:outline-none border rounded-lg resize-none py-1 px-1"
            placeholder='e.g. enter if "x" if not existent'
          ></textarea>
        </div>
      </div>
      <div className="mb-1">
        <label
          htmlFor={field.id + '-answerpossibilities'}
          className={
            'bg-white block py-2 px-1 rounded-lg hover:bg-blue-500 hover:text-white w-[100%] overflow-visible mt-1 mb-1' +
            (field?.answer_possibilities ? ' text-white bg-blue-500' : '')
          }
        >
          Add Answer Option
        </label>
        <input
          type="checkbox"
          id={field.id + '-answerpossibilities'}
          className="hidden add-opt-btn"
        />
        <div className=" transition-all">
          {field?.answer_possibilities?.map((answer_possibility, l) => (
            <div key={l} className="flex items-center justify-between">
              <input
                className="w-[87.5%] focus:outline-none p-1 rounded-lg border"
                value={
                  typeof answer_possibility === 'string'
                    ? answer_possibility
                    : answer_possibility?.opt
                }
                onChange={(e) => handleAnswerOptChange(e, i, j, l)}
              />

              {/* Color Input */}

              <div className="relative">
                <label
                  htmlFor={
                    (typeof answer_possibility === 'string'
                      ? answer_possibility + field.id
                      : answer_possibility.opt + field.id) + '-colorchange'
                  }
                  className="w-[100%] mx-1 px-5 py-1.5 rounded-lg bg-neutral-200 cursor-pointer text-neutral-600 "
                  style={answer_possibility?.color && { backgroundColor: answer_possibility.color }}
                >
                  <span></span>
                  <span className="absolute top-0 bottom-0 right-0 left-0 m-auto">
                    {!answer_possibility?.color && '+'}
                  </span>
                </label>
                <input
                  id={
                    (typeof answer_possibility === 'string'
                      ? answer_possibility + field.id
                      : answer_possibility.opt + field.id) + '-colorchange'
                  }
                  type="checkbox"
                  className="color-input"
                />

                {/* Color Palette for Answer Options */}

                <div className="color-container  rounded-lg shadow-basic absolute bg-white z-20 right-0 mt-2">
                  <p className="text-sm pt-2">Colors</p>
                  <div className="flex pb-2.5 px-4">
                    <div className="">
                      <button
                        onClick={() => handleAnswerOptColorChange('#bbf7d0', i, j, l)}
                        className="color-btn bg-green-200"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#4ade80', i, j, l)}
                        className="color-btn bg-green-400"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#22c55e', i, j, l)}
                        className="color-btn bg-green-500"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#16a34a', i, j, l)}
                        className="color-btn bg-green-600"
                      ></button>
                    </div>
                    <div className="ml-3.5">
                      <button
                        onClick={() => handleAnswerOptColorChange('#fed7aa', i, j, l)}
                        className="color-btn bg-orange-200"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#fb923c', i, j, l)}
                        className="color-btn bg-orange-400"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#f97316', i, j, l)}
                        className="color-btn bg-orange-500"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#ea580c', i, j, l)}
                        className="color-btn bg-orange-600"
                      ></button>
                    </div>
                    <div className="ml-3.5">
                      <button
                        onClick={() => handleAnswerOptColorChange('#fef08a', i, j, l)}
                        className="color-btn bg-yellow-200"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#facc15', i, j, l)}
                        className="color-btn bg-yellow-400"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#eab308', i, j, l)}
                        className="color-btn bg-yellow-500"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#ca8a04', i, j, l)}
                        className="color-btn bg-yellow-600"
                      ></button>
                    </div>
                    <div className="ml-3.5">
                      <button
                        onClick={() => handleAnswerOptColorChange('#fecaca', i, j, l)}
                        className="color-btn bg-red-200"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#f87171', i, j, l)}
                        className="color-btn bg-red-400"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#ef4444', i, j, l)}
                        className="color-btn bg-red-500"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#dc2626', i, j, l)}
                        className="color-btn bg-red-600"
                      ></button>
                    </div>
                    <div className="ml-3.5">
                      <button
                        onClick={() => handleAnswerOptColorChange('#e5e5e5', i, j, l)}
                        className="color-btn bg-neutral-200"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#a3a3a3', i, j, l)}
                        className="color-btn bg-neutral-400"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#bfdbfe', i, j, l)}
                        className="color-btn bg-blue-200"
                      ></button>
                      <button
                        onClick={() => handleAnswerOptColorChange('#60a5fa', i, j, l)}
                        className="color-btn bg-blue-400"
                      ></button>
                    </div>
                  </div>
                  <div className="px-2.5 pt-0.5 pb-2">
                    <button
                      onClick={() => handleAnswerOptColorChange(undefined, i, j, l)}
                      className="rounded-full hover:scale-[1.025] transition-all w-[100%] bg-neutral-500 text-sm py-1 text-white"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="mx-auto border-2 p-1.5 border-blue-400 text-blue-400 hover:bg-blue-400 hover:text-white transition-all rounded-full"
                onClick={() => deleteAnswerOpt(i, j, l)}
              >
                <MinusSmIcon className="w-5 h-5" />
              </button>
            </div>
          ))}

          <button
            className="block py-2 px-1 rounded-lg text-center w-[100%] hover:bg-blue-400 hover:text-white transition-all mb-2 mt-1 bg-white"
            onClick={() => addAnswerOpt(i, j)}
          >
            <PlusSmIcon className="w-5 h-5 mx-auto" />
          </button>
        </div>
      </div>
    </div>
  );
});

export default AdditionalPropsMenu;
