import { deleteField, doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';

function AuthButton({ saveCall, setSaveCall, subsidiary, user, companyId, yearId }) {
  const [subsidiaryData, setSubsidiaryData] = useState(subsidiary.data());
  const [changed, setChanged] = useState(false);

  /// Handling Auth Changes ///

  function changeAuthorization() {
    const updatedSubsidiary = { ...subsidiaryData };

    if (!changed) {
      setChanged(true);
    } else {
      setChanged(false);
    }

    if (updatedSubsidiary.roles[user.id]) {
      delete updatedSubsidiary.roles[user.id];
    } else {
      updatedSubsidiary.roles[user.id] = 'editor';
    }

    setSubsidiaryData(updatedSubsidiary);
  }

  /// Handling Color Changes ///

  function getAuthState(sub) {
    if (user) {
      if (sub?.roles?.[user.id] === 'editor') {
        return 'bg-green-400 ';
      } else {
        return 'bg-red-400 ';
      }
    } else {
      return 'bg-amber-400 ';
    }
  }

  /// Saving Changes ///

  useEffect(() => {
    if (saveCall) {
      saveAuthorization();
      setSaveCall(false);
    }
  }, [saveCall]);

  async function saveAuthorization() {
    if (changed) {
      await updateDoc(
        doc(db, 'user_data', companyId, 'years', yearId, 'subsidaries', subsidiary.id),
        {
          roles: subsidiaryData.roles,
        }
      );

      const rolesLocation = 'roles.' + user.id;

      if (Object.keys(subsidiaryData.roles).includes(user.id)) {
        await updateDoc(
          doc(db, 'user_data', companyId, 'folders', yearId, 'subfolders', subsidiary.id),
          {
            [rolesLocation]: 'editor',
          }
        );
      } else {
        await updateDoc(
          doc(db, 'user_data', companyId, 'folders', yearId, 'subfolders', subsidiary.id),
          {
            [rolesLocation]: deleteField(),
          }
        );
      }
    }
  }

  /// HTML Component ///

  return (
    <button className={getAuthState(subsidiaryData) + 'auth-button'} onClick={changeAuthorization}>
      {subsidiary.id}
    </button>
  );
}

export default AuthButton;
