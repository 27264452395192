import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import React, { useRef, useState } from 'react';
import { animateButton, db, useAuth } from '../../firebase';

function AddCompany({ setSelectedCompany }) {
  const currentUser = useAuth();
  const [companyName, setCompanyName] = useState('');

  const [companyCreated, setCompanyCreated] = useState(false);

  /// Add Client ///

  async function addCompany() {
    if (companyName === '') {
      return;
    }

    const companies = await getDocs(collection(db, 'user_data'));
    const companyNames = companies.docs.map((company) => company.id);

    if (companyNames.includes(companyName)) {
      alert('That company alerady exists!');
      return;
    }

    await setDoc(doc(db, 'user_data', companyName), {
      roles: {},
      users: {},
      superEditing: {},
    });

    await setDoc(doc(db, 'user_data', companyName, 'folders', 'IDR'), {
      name: 'IDR',
    });

    const newCompany = await getDoc(doc(db, 'user_data', companyName));
    setSelectedCompany(newCompany);

    animateButton(setCompanyCreated, 2000);
  }

  /// HTML Component ///

  return (
    <div className="component-container w-[100%] lg:w-[75%] block">
      <div className="component-icon-container bg-purple-400">
        <OfficeBuildingIcon className="w-8 h-8" />
      </div>
      <p className="text-2xl p-1 m-1">Add Client</p>
      <div className="flex items-center w-[100%]">
        <div className="w-[100%] mt-2 flex justify-between text-md">
          <input
            className="p-1 w-[60%] border rounded-lg mx-1 focus:outline-none focus:border-slate-400"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Client Name"
          />
          <div className="w-[40%] relative mx-1 overflow-hidden">
            <button
              className="w-[100%] rounded-lg bg-purple-400 text-white  hover:shadow-lg transition-all p-1"
              onClick={addCompany}
            >
              Create
            </button>
            <div
              className={
                'absolute w-[100%] top-0 rounded-lg bg-green-400 text-white hover:shadow-lg transition-all p-1 text-center ' +
                (companyCreated ? 'left-0' : 'left-[100%]')
              }
              onClick={addCompany}
            >
              + Created
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCompany;
