import { EyeIcon, XIcon } from '@heroicons/react/outline';
import { EyeIcon as EyeIcon2, EyeOffIcon } from '@heroicons/react/solid';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, login, useAuth } from '../../firebase';
import ForgotPasswordForm from '../Help/ForgotPasswordForm';

function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const currentUser = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordFormVisible, setPasswordFormVisible] = useState();
  const navigate = useNavigate();

  async function handleLogin() {
    try {
      await login(emailRef.current.value, passwordRef.current.value);
      navigate('/welcome');
    } catch (error) {
      // Handle different error cases
      switch (error.code) {
        case 'auth/invalid-login-credentials':
          setErrorMessage('Invalid email or password');
          break;
        case 'auth/user-not-found':
          setErrorMessage('User not found');
          break;
        case 'auth/invalid-email':
          setErrorMessage('Invalid email format');
          break;
        case 'auth/too-many-requests':
          setErrorMessage('Too many attempts. Try again later');
          break;
        default:
          setErrorMessage('Login failed. Please try again');
      }
    }
  }

  function handlePasswordVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <>
      <div
        onClick={() => setPasswordFormVisible(false)}
        className={
          'w-[100%] h-[100%] bg-neutral-500/30 absolute top-0 z-40' +
          (passwordFormVisible ? ' ' : ' hidden')
        }
      ></div>
      <div
        className={
          passwordFormVisible
            ? 'font-body absolute top-0 bottom-96 right-0 left-0 m-auto z-[100] w-[44rem] h-max'
            : 'hidden'
        }
      >
        <ForgotPasswordForm title="Reset Password" />
      </div>

      <div className="font-body mt-20">
        <div className={'p-10 rounded-lg bg-white w-[80%] md:w-[38rem] mx-auto shadow-lg relative'}>
          <p className="text-center text-3xl mb-5">Login</p>
          <div className="flex flex-col justify-center">
            <input
              className="w-[100%] my-[2px] p-1 border text-lg rounded-md focus:outline-none focus:border-slate-400"
              ref={emailRef}
              placeholder="Email"
            />
            <div className="w-[100%] my-[2px] border text-lg focus:border-slate-400 flex bg-white items-center rounded-md">
              <input
                className="w-[100%] focus:outline-none p-1 border-0 rounded-md"
                ref={passwordRef}
                placeholder="Password"
                type={passwordVisible ? '' : 'password'}
              />
              {passwordVisible && (
                <EyeIcon2
                  className="w-6 h-6 mr-2 inline text-slate-400 bg-white cursor-pointer"
                  onClick={handlePasswordVisibility}
                />
              )}
              {!passwordVisible && (
                <EyeOffIcon
                  className="w-6 h-6 mr-2 inline text-slate-400 bg-white cursor-pointer"
                  onClick={handlePasswordVisibility}
                />
              )}
            </div>
            <button
              className="w-[100%] p-2 text-white border border-blue-500 bg-blue-500"
              onClick={handleLogin}
            >
              Login
            </button>
            <div className="relative">
              <span
                className="text-sm text-indigo-500 cursor-pointer hover:border-b hover:border-indigo-500 transition-all"
                onClick={() => setPasswordFormVisible(!passwordFormVisible)}
              >
                Password forgotten?
              </span>
              {errorMessage && (
                <span className="text-sm text-red-500 text-right absolute right-0">
                  {errorMessage}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
