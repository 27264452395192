import { ArrowLeftIcon } from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react';

const InfoMainInput = React.memo(({ field, answer, done, handleAnswerChange }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef();
  const dropdownBtnRef = useRef();

  const [customInput, setCustomInput] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownBtnRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
      {field?.answer_possibilities && (
        <div className="relative">
          {!customInput ? (
            <button
              ref={dropdownBtnRef}
              onClick={() => setDropdownVisible((prev) => !prev)}
              className="w-[100%] border rounded-lg py-2 text-center mx-auto"
            >
              {answer || 'Select Answer'}
            </button>
          ) : (
            <div>
              <textarea
                className="w-[100%] border rounded-lg p-1 min-h-40"
                value={answer || ''}
                onChange={(e) => handleAnswerChange(e.target.value, field.id)}
                placeholder={field?.placeholder || ''}
              ></textarea>
              <button
                className="p-1 flex justify-center items-center text-sm w-[100%] bg-red-400 rounded-lg text-white hover:bg-red-500 transition-all"
                onClick={() => setCustomInput(false)}
              >
                <ArrowLeftIcon className="w-4 h-4 mr-2" />
                Return to Selection
              </button>
            </div>
          )}
          <div
            ref={dropdownRef}
            className="absolute w-[100%] bg-white shadow-lg px-1 z-[5] rounded-lg max-h-60 overflow-y-auto"
          >
            <div className={dropdownVisible ? '' : 'hidden'}>
              <button
                onClick={() => {
                  setCustomInput(true);
                  setDropdownVisible(false);
                }}
                className="w-[100%] text-blue-400 rounded-lg my-1 p-1 hover:bg-blue-400 hover:text-white"
              >
                Custom
              </button>
              {field?.answer_possibilities?.map((answerOpt) => (
                <button
                  className="w-[100%] rounded-lg my-1 p-1 hover:bg-blue-400 hover:text-white"
                  onClick={() => {
                    handleAnswerChange(
                      typeof answerOpt === 'string' ? answerOpt : answerOpt.opt,
                      field.id
                    );
                    setDropdownVisible(false);
                  }}
                >
                  {typeof answerOpt === 'string' ? answerOpt : answerOpt.opt}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {!field?.answer_possibilities && done && (
        <span className="w-[100%] rounded-lg p-1">{answer || ''}</span>
      )}
      {!field?.answer_possibilities && !done && (
        <textarea
          className="w-[100%] border rounded-lg p-1 h-[100%]"
          value={answer || ''}
          onChange={(e) => handleAnswerChange(e.target.value, field.id)}
          placeholder={field?.placeholder || ''}
        ></textarea>
      )}
    </>
  );
});

export default InfoMainInput;
