import { InformationCircleIcon } from '@heroicons/react/solid';
import React from 'react';

function Tool({ desc, icon, toolFunction, infoRight }) {
  return (
    <div className="relative">
      <button className="tool-btn save-btn" onClick={toolFunction}>
        {icon}
      </button>
      <div
        className={
          'info-container shadow-basic save-information ' + (infoRight ? 'left-0' : 'right-0')
        }
      >
        <div className="mr-4 border-r px-2 flex items-center justify-center">
          <InformationCircleIcon className="w-5 h-5 inline mr-1" />
        </div>
        <p>{desc}</p>
      </div>
    </div>
  );
}

export default Tool;
