import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import React, { useState } from 'react';

const ChangeCategoryOrder = React.memo(
  ({ setFormData, changeCategoryIndex, changeCategoriesVisible }) => {
    /// Change Category Order ///

    function changeCategoryUp(categoryIndex) {
      setFormData((prev) => {
        const updatedFormData = { ...prev };

        if (updatedFormData.categories[categoryIndex - 1]) {
          const categoriesCopy = [...updatedFormData.categories];
          [categoriesCopy[categoryIndex - 1], categoriesCopy[categoryIndex]] = [
            categoriesCopy[categoryIndex],
            categoriesCopy[categoryIndex - 1],
          ];
          updatedFormData.categories = categoriesCopy;
        }

        return updatedFormData;
      });
    }

    function changeCategoryDown(categoryIndex) {
      setFormData((prev) => {
        const updatedFormData = { ...prev };

        if (updatedFormData.categories[categoryIndex + 1]) {
          const categoriesCopy = [...updatedFormData.categories];
          [categoriesCopy[categoryIndex + 1], categoriesCopy[categoryIndex]] = [
            categoriesCopy[categoryIndex],
            categoriesCopy[categoryIndex + 1],
          ];
          updatedFormData.categories = categoriesCopy;
        }

        return updatedFormData;
      });
    }

    return (
      <div
        className={
          'absolute left-8 flex items-center justify-center ' +
          (changeCategoriesVisible ? '' : 'hidden')
        }
      >
        <button className="bg-sky-600 text-white rounded-full p-1.5 mr-1.5">
          <ArrowUpIcon className="w-5 h-5" onClick={() => changeCategoryUp(changeCategoryIndex)} />
        </button>
        <button className="bg-sky-600 text-white rounded-full p-1.5">
          <ArrowDownIcon
            className="w-5 h-5"
            onClick={() => changeCategoryDown(changeCategoryIndex)}
          />
        </button>
      </div>
    );
  }
);

export default ChangeCategoryOrder;
