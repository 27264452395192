import { LibraryIcon } from '@heroicons/react/outline';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import { uploadBytes } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { db } from '../../firebase';
import IdDropdown from '../../components/IdDropdown';

function ManageClients({ selectedCompany }) {
  const [yearsVisible, setYearsVisible] = useState();
  const [subsidiariesVisible, setSubsidiariesVisible] = useState();

  const [years, setYears] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);

  const [selectedSubsidiary, setSelectedSubsidiary] = useState();
  const [selectedYear, setSelectedYear] = useState();

  const [subName, setSubName] = useState('');

  /// Copying Clients from years

  const [copySub, setCopySub] = useState(false);

  const [copyYearsVisible, setCopyYearsVisible] = useState();
  const [copySubsVisible, setCopySubsVisible] = useState();

  const [selectedCopyYear, setSelectedCopyYear] = useState();

  const [availableCopySubs, setAvailableCopySubs] = useState([]);
  const [selectedCopySub, setSelectedCopySub] = useState();

  /// Loading available Document Types ///

  useEffect(() => {
    if (selectedCompany) {
      const unsub = onSnapshot(
        collection(db, 'user_data', selectedCompany?.id, 'years'),
        (querySnapshot) => {
          setYears(querySnapshot?.docs);
        }
      );
    }
  }, [selectedCompany]);

  /// Document Type Selection ///

  async function selectYear(year) {
    setYearsVisible(false);
    const newSubsidiaries = await getDocs(
      collection(db, 'user_data', selectedCompany.id, 'years', year.id, 'subsidaries')
    );

    setSelectedYear(year);
    setSubsidiaries(newSubsidiaries.docs);
  }

  /// Document Selection ///

  function selectSubsidiary(Document) {
    setSubsidiariesVisible(false);
    setSelectedSubsidiary(Document);
  }

  function selectCopyYear(year) {
    setCopySubsVisible(true);
    setCopyYearsVisible(false);
    setSelectedCopyYear(year);
  }

  function selectCopySub(sub) {
    setCopySubsVisible(false);
    setSelectedCopySub(sub);
    setSubName(sub.id);
  }

  useEffect(() => {
    async function fetchCopySubs() {
      const fetchedSubsidiaries = await getDocs(
        collection(db, 'user_data', selectedCompany.id, 'years', selectedCopyYear.id, 'subsidaries')
      );
      setAvailableCopySubs(fetchedSubsidiaries.docs);
    }

    if (selectedCopyYear !== undefined) {
      fetchCopySubs();
    }
  }, [selectedCopyYear]);

  /// Managing Clients (Documents) ///

  async function addClient() {
    if (copySub) {
      const sub = availableCopySubs.find((avSub) => avSub.id === selectedCopySub.id);

      // Copy Document Formdata

      const existingSub = await getDoc(
        doc(db, 'user_data', selectedCompany.id, 'years', selectedYear.id, 'subsidaries', subName)
      );
      if (existingSub?.data()?.roles && existingSub?.data()?.fieldValues) {
        console.log('Document already exists.');
        return;
      }

      if (!sub) {
        return;
      }

      await setDoc(
        doc(db, 'user_data', selectedCompany.id, 'years', selectedYear.id, 'subsidaries', subName),
        {
          fieldValues: sub.data().fieldValues,
          roles: sub.data().roles ? sub.data().roles : {},
        }
      );

      // Copy Document Folder

      console.log(selectedSubsidiary);
      console.log(selectedYear);
      console.log(selectedCompany);

      const exisitingFolder = await getDoc(
        doc(
          db,
          'user_data',
          selectedCompany.id,
          'folders',
          selectedYear.id,
          'subfolders',
          selectedCopySub.id
        )
      );
      const exisitingFolderData = exisitingFolder.data();

      await setDoc(
        doc(db, 'user_data', selectedCompany.id, 'folders', selectedYear.id, 'subfolders', subName),
        {
          files: exisitingFolderData.files,
          roles: exisitingFolderData.roles,
          name: subName,
        }
      );
    } else {
      await setDoc(
        doc(db, 'user_data', selectedCompany.id, 'years', selectedYear.id, 'subsidaries', subName),
        {
          fieldValues: [],
          roles: {},
        }
      );

      await setDoc(
        doc(db, 'user_data', selectedCompany.id, 'folders', selectedYear.id, 'subfolders', subName),
        {
          name: subName,
          files: [],
          roles: {},
        }
      );
    }
  }

  async function deleteClient() {
    await deleteDoc(
      doc(
        db,
        'user_data',
        selectedCompany.id,
        'years',
        selectedYear.id,
        'subsidaries',
        selectedSubsidiary.id
      )
    );
    await deleteDoc(
      doc(
        db,
        'user_data',
        selectedCompany.id,
        'subfolder',
        selectedYear.id,
        'subfolders',
        selectedSubsidiary.id
      )
    );
  }

  /// HTML Component ///

  return (
    <div className="component-container w-[100%] lg:w-[75%] block relative z-10">
      <div className="component-icon-container bg-emerald-400">
        <LibraryIcon className="w-8 h-8" />
      </div>
      <p className="text-2xl p-1 m-1">Manage Documents</p>
      <div className="mt-3">
        <div className="flex mb-2 ">
          <IdDropdown
            width={'100%'}
            color={'emerald'}
            options={years}
            selectedOption={selectedYear}
            dropdownVisible={yearsVisible}
            setDropdownVisible={setYearsVisible}
            selectFunction={selectYear}
            placeholder={'Select Document Type of Documentation'}
          />
        </div>

        <div className="w-[100%] px-1 flex mb-2">
          {/* New / Copy Selection */}

          <div style={copySub ? { width: '60%' } : { width: '20%' }} className="flex gap-x-1">
            <button
              style={copySub ? { width: '20%' } : { width: '100%' }}
              onClick={() => setCopySub((prev) => !prev)}
              className="mx-1 border border-emerald-400 text-emerald-400 text-center w-[100%] rounded-lg h-[100%]"
            >
              {copySub ? 'Copy' : 'New'}
            </button>

            {copySub && (
              <>
                <IdDropdown
                  width={'40%'}
                  color={'emerald'}
                  options={years}
                  selectedOption={selectedCopyYear}
                  dropdownVisible={copyYearsVisible}
                  setDropdownVisible={setCopyYearsVisible}
                  selectFunction={selectCopyYear}
                  placeholder={'From Document Type'}
                />
                <IdDropdown
                  width={'40%'}
                  color={'emerald'}
                  options={availableCopySubs}
                  selectedOption={selectedCopySub}
                  dropdownVisible={copySubsVisible}
                  setDropdownVisible={setCopySubsVisible}
                  selectFunction={selectCopySub}
                  placeholder={'Sub'}
                />
              </>
            )}
          </div>

          {/* Final Adding */}

          <div
            style={copySub ? { width: '20%' } : { width: '40%' }}
            className="mx-0.5 transition-all "
          >
            <input
              className="p-1 border rounded-lg w-[100%] focus:outline-none focus:border-slate-400"
              value={subName}
              onChange={(e) => setSubName(e.target.value)}
              placeholder="e.g. Company or Contract Number"
            />
          </div>
          <div
            style={copySub ? { width: '20%' } : { width: '40%' }}
            className="mx-0.5 transition-all "
          >
            <button
              className="p-1 border border-cyan-400 rounded-lg bg-emerald-400 text-white w-[100%] transition-all hover:shadow-lg"
              onClick={addClient}
            >
              Add
            </button>
          </div>
        </div>

        {/* Delete Document */}

        <div className="w-[100%] px-1 flex">
          <IdDropdown
            width={'66.6666%'}
            color={'emerald'}
            options={subsidiaries}
            selectedOption={selectedSubsidiary}
            dropdownVisible={subsidiariesVisible}
            setDropdownVisible={setSubsidiariesVisible}
            selectFunction={selectSubsidiary}
            placeholder={'Document'}
          />
          <div className="w-1/3 mx-1">
            <button
              className="p-1 border border-red-400 rounded-lg bg-red-400 text-white w-[100%] transition-all hover:shadow-lg"
              onClick={deleteClient}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageClients;
