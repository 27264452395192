import { InformationCircleIcon } from '@heroicons/react/outline';
import React from 'react';

const NumbersInput = React.memo(
  ({
    numbersCategoryIndex,
    numbersRowIndex,
    setFormData,
    formData,
    showSelectableRows,
    numbersField,
    neededFieldException,
    neededFieldVisible,
  }) => {
    function showCalculationParts(categoryIndex, formula) {
      var regularExpression = /\[(.*?)\]/g;

      // console.log(formula)
      const matches = formula.match(regularExpression);
      if (matches) {
        const matchTopics = matches.map(
          (match) =>
            formData.categories[categoryIndex].fields.find(
              (field) => field.id === parseInt(match.replace('[', '').replace(']', ''))
            )?.topic || 'Field unfound.'
        );
        return matchTopics;
      } else {
        return false;
      }
    }

    function changeFormula(e, categoryIndex, rowIndex) {
      setFormData((prev) => {
        const updatedFormData = { ...prev };

        updatedFormData.categories[categoryIndex].fields[rowIndex].formula = e.target.value;

        return updatedFormData;
      });
    }

    return (
      <div className="w-1/2 float-left flex items-center justify-center">
        <input
          className="border rounded-lg p-1 w-[75%] transition-all visibility-field"
          placeholder="Add formula"
          onChange={(e) => changeFormula(e, numbersCategoryIndex, numbersRowIndex)}
          value={numbersField.formula || ''}
        />
        <button
          className={
            'border rounded-lg p-1 w-auto ml-2 hover:bg-blue-500 hover:border-transparent hover:text-white' +
            (neededFieldVisible && numbersField.id === neededFieldException
              ? ' bg-blue-500 text-white border-transparent'
              : '')
          }
          onClick={() => showSelectableRows(numbersCategoryIndex, numbersRowIndex)}
        >
          Field
        </button>

        {/* Show Calculation Parts */}

        {showCalculationParts(numbersCategoryIndex, numbersField.formula) && (
          <div className="absolute bg-white rounded-lg bottom-16 shadow-basic visibility-item transition-all">
            <div className="p-2 flex">
              <div className="w-12 pr-1.5 mr-2 border-r flex items-center justify-center">
                <InformationCircleIcon className="w-5 h-5 ml-1 mb-1" />
              </div>

              <div className="flex text-center text-sm">
                {showCalculationParts(numbersCategoryIndex, numbersField.formula).map(
                  (calculationPart, k) => (
                    <span
                      key={k}
                      className="w-32 mr-1 p-1 border rounded-md flex items-center justify-center"
                    >
                      {calculationPart}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default NumbersInput;
