import React from 'react';
import NotificationHistory from './NotificationHistory';
import NotifyStaff from './NotifyStaff';

function MailingBoard({ selectedCompany }) {
  return (
    <div className="flex flex-col lg:flex-row w-[100%] bg-white">
      <NotificationHistory selectedCompany={selectedCompany} />
      <NotifyStaff selectedCompany={selectedCompany} />
    </div>
  );
}

export default MailingBoard;
