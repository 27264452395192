import React, { useCallback } from 'react';
import InfoMainInput from './InfoMainInput';
import NumbersInput from './NumbersInput';
import TransactionAmountInput from './TransactionAmountInput';
import TransactionPartnerInput from './TransactionPartnerInput';

function FormFields({
  selectedSubsidiary,
  formData,
  userData,
  setUserData,
  superEditor,
  masterUser,
}) {
  /// Changing User Data ///

  const handleAnswerChange = useCallback((answer, fieldId) => {
    setUserData((prev) => {
      const updatedUserData = { ...prev };
      const changeFieldArray = updatedUserData.fieldValues.find(
        (fieldValue) => fieldValue.id === fieldId
      );

      if (changeFieldArray) {
        changeFieldArray.answer = answer;
      } else {
        updatedUserData.fieldValues.push({
          id: fieldId,
          answer: answer,
          comment: '',
          done: false,
        });
      }

      return updatedUserData;
    });
  }, []);

  function handleRemarkChange(e, fieldId, ctype) {
    const updatedUserData = { ...userData };

    const changeFieldArray = updatedUserData.fieldValues.find(
      (fieldValue) => fieldValue.id === fieldId
    );

    if (changeFieldArray) {
      changeFieldArray.comment = e.target.value;
    } else {
      if (ctype === 'information') {
        updatedUserData.fieldValues.push({
          id: fieldId,
          answer: '',
          comment: e.target.value,
          done: false,
        });
      } else if (ctype === 'individual-transactions') {
        updatedUserData.fieldValues.push({
          id: fieldId,
          transaction_partner: '',
          transaction_amount: '',
          comment: e.target.value,
          done: false,
        });
      } else if (ctype === 'standard-transactions') {
        updatedUserData.fieldValues.push({
          id: fieldId,
          transaction_amount: '',
          comment: e.target.value,
          done: false,
        });
      }
    }

    setUserData(updatedUserData);
  }

  function handleStatusChange(e, fieldId, ctype) {
    if (!superEditor && !masterUser) {
      return;
    }

    const updatedUserData = { ...userData };

    const changeFieldArray = updatedUserData.fieldValues.find(
      (fieldValue) => fieldValue.id === fieldId
    );

    if (changeFieldArray) {
      changeFieldArray.done = e.target.checked;
    } else {
      if (ctype === 'information') {
        updatedUserData.fieldValues.push({
          id: fieldId,
          answer: '',
          comment: '',
          done: e.target.checked,
        });
      } else if (ctype === 'individual-transactions') {
        updatedUserData.fieldValues.push({
          id: fieldId,
          transaction_partner: '',
          transaction_amount: '',
          comment: '',
          done: e.target.checked,
        });
      } else if (ctype === 'standard-transactions') {
        updatedUserData.fieldValues.push({
          id: fieldId,
          transaction_amount: '',
          comment: '',
          done: e.target.checked,
        });
      } else if (ctype === 'numbers') {
        updatedUserData.fieldValues.push({
          id: fieldId,
          transaction_amount: '',
          comment: '',
          done: e.target.checked,
        });
      }
    }

    setUserData(updatedUserData);
  }

  const handleTransactionPartnerChange = useCallback((e, fieldId) => {
    console.log('Trigger detected.');

    setUserData((prev) => {
      const updatedUserData = { ...prev };

      const changeFieldArray = updatedUserData.fieldValues.find(
        (fieldValue) => fieldValue.id === fieldId
      );

      if (changeFieldArray) {
        changeFieldArray.transaction_partner = e.target.value;
      } else {
        updatedUserData.fieldValues.push({
          id: fieldId,
          transaction_amount: '',
          transaction_partner: e.target.value,
          comment: '',
          done: false,
        });
      }

      return updatedUserData;
    });
  }, []);

  const handleTransactionAmountChange = useCallback((e, fieldId, ctype) => {
    setUserData((prev) => {
      const updatedUserData = { ...prev };

      var cleanedAmount = e.target.value.toString().replace(/,/g, '');

      cleanedAmount = cleanedAmount.replace(/[a-z]+/g, '');

      if (cleanedAmount.slice(-1) === '.') {
        cleanedAmount =
          parseFloat(cleanedAmount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.';
      } else {
        cleanedAmount = cleanedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ','); //.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      const changeFieldArray = updatedUserData.fieldValues.find(
        (fieldValue) => fieldValue.id === fieldId
      );

      if (changeFieldArray) {
        changeFieldArray.transaction_amount = cleanedAmount;
      } else {
        if (ctype === 'individual-transactions') {
          updatedUserData.fieldValues.push({
            id: fieldId,
            transaction_partner: '',
            transaction_amount: cleanedAmount,
            comment: '',
            done: false,
          });
        } else if (ctype === 'standard-transactions') {
          updatedUserData.fieldValues.push({
            id: fieldId,
            transaction_amount: cleanedAmount,
            comment: '',
            done: false,
          });
        } else if (ctype === 'numbers') {
          updatedUserData.fieldValues.push({
            id: fieldId,
            transaction_amount: cleanedAmount,
            comment: '',
            done: false,
          });
        }
      }

      return updatedUserData;
    });
  }, []);

  /// Calculate Auto-Calc Fields ///

  function handleNumbersChange(e, fieldId, categoryType) {
    // handleTransactionAmountChange(e, fieldId, categoryType)

    setUserData((prev) => {
      const updatedUserData = { ...prev };

      // Formatting new transaction amount

      var cleanedAmount = e.target.value.toString().replace(/,/g, '');

      // Cleans all letters from the input
      cleanedAmount = cleanedAmount.replace(/[a-z]+/g, '');

      // Cleans all "-" that are not at the first place from the input
      cleanedAmount = cleanedAmount.replace(/(?!^)-/g, '');

      if (cleanedAmount.slice(-1) === '.') {
        cleanedAmount =
          parseFloat(cleanedAmount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.';
      } else {
        cleanedAmount = cleanedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }

      // Setting new transaction amount

      const changeFieldArray = updatedUserData.fieldValues.find(
        (fieldValue) => fieldValue.id === fieldId
      );

      if (changeFieldArray) {
        changeFieldArray.transaction_amount = cleanedAmount;
      } else {
        updatedUserData.fieldValues.push({
          id: fieldId,
          transaction_amount: cleanedAmount,
          comment: '',
          done: false,
        });
      }

      // Update all number fields

      const toBeChangedFields = formData.categories
        .find((category) => category.type === 'numbers')
        .fields.filter((field) => field?.formula !== '');

      for (const toBeChangedField of toBeChangedFields) {
        const checkChangeField = updatedUserData.fieldValues.find(
          (fieldValue) => fieldValue.id === toBeChangedField.id
        );

        if (!checkChangeField) {
          updatedUserData.fieldValues.push({
            id: toBeChangedField.id,
            transaction_amount: '',
            comment: '',
            done: false,
          });
        }

        const changeField = updatedUserData.fieldValues.find(
          (fieldValue) => fieldValue.id === toBeChangedField.id
        );
        changeField.transaction_amount = calculateFormula(toBeChangedField.formula);
      }

      return updatedUserData;
    });
  }

  function calculateFormula(formula) {
    const matches = formula.match(/\[(.*?)\]/g);

    var calculation = formula;

    for (const match of matches) {
      const plainMatch = match.replace('[', '').replace(']', '');

      // replaces all "," from number to work with it
      var matchNumber =
        userData.fieldValues
          .find((fieldValue) => fieldValue.id === parseInt(plainMatch))
          ?.transaction_amount?.toString()
          .replace(/,/g, '') || 0;
      if (matchNumber[0] === '-') {
        if (matchNumber.length === 1) {
          matchNumber = '(' + 0 + ')';
        } else {
          matchNumber = '(' + matchNumber + ')';
        }
      }

      const matchInSq = '[' + plainMatch + ']';
      calculation = calculation.replace(matchInSq, matchNumber);
    }

    if (eval(calculation) === Infinity || eval(calculation) === -Infinity) {
      return 0;
    } else {
      return eval(calculation);
    }
  }

  function numberWithCommas(x) {
    if (x !== '') {
      const numberWithComma = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (!numberWithComma.includes('.')) {
        return numberWithComma + '.00';
      } else {
        return numberWithComma;
      }
    } else {
      return 0;
    }
  }

  return (
    <>
      {formData && (
        <div className="py-0 flex flex-col overflow-y-auto grow mt-[108px]">
          {selectedSubsidiary?.id && (
            <div>
              <div className="flex flex-col">
                {formData?.categories?.map((c, index) => (
                  <div key={c.type + index} className="flex flex-col">
                    {/* Category Name */}

                    <div className="w-1/1 my-[2px] mt-0 text-lg py-2 border-b bg-blue-300 text-center">
                      {c.name}
                    </div>

                    {/* Informational Data */}

                    <div className="flex flex-col">
                      {c.fields.map((field) => (
                        <div className="w-[100%] mt-[2px] text-lg py-2 border-b">
                          <div className="w-[95%] px-5 mx-auto flex items-stretch">
                            {/* Field Topic */}
                            <div className="w-[23%]">{field.topic}</div>

                            {/* Field Description or Additional Input */}
                            <div className="w-[23%] text-base">
                              {c.type !== 'individual-transactions' ? (
                                field.description
                              ) : (
                                <TransactionPartnerInput
                                  field={field}
                                  transaction_partner={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.transaction_partner
                                  }
                                  done={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.done
                                  }
                                  category={c}
                                  handleTransactionPartnerChange={handleTransactionPartnerChange}
                                />
                              )}
                            </div>

                            {/* Field Main Input */}
                            <div className="w-[23%] text-base px-2">
                              {c.type === 'information' && (
                                <InfoMainInput
                                  field={field}
                                  answer={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.answer
                                  }
                                  done={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.done
                                  }
                                  handleAnswerChange={handleAnswerChange}
                                />
                              )}
                              {c.type === 'individual-transactions' && (
                                <TransactionAmountInput
                                  field={field}
                                  transaction_amount={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.transaction_amount
                                  }
                                  done={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.done
                                  }
                                  category={c}
                                  handleTransactionAmountChange={handleTransactionAmountChange}
                                />
                              )}
                              {c.type === 'standard-transactions' && (
                                <TransactionAmountInput
                                  field={field}
                                  transaction_amount={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.transaction_amount
                                  }
                                  done={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.done
                                  }
                                  category={c}
                                  handleTransactionAmountChange={handleTransactionAmountChange}
                                />
                              )}
                              {c.type === 'numbers' && (
                                <NumbersInput
                                  field={field}
                                  transaction_amount={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.transaction_amount
                                  }
                                  done={
                                    userData?.fieldValues?.find(
                                      (fieldValue) => fieldValue.id === field.id
                                    )?.done
                                  }
                                  category={c}
                                  handleNumbersChange={handleNumbersChange}
                                  numberWithCommas={numberWithCommas}
                                />
                              )}
                            </div>

                            {/* Remark Input */}
                            <div className="w-[23%] text-base">
                              <textarea
                                className="w-[100%] border rounded-lg p-1 text-red-500 h-[100%]"
                                value={
                                  userData?.fieldValues?.find(
                                    (fieldValue) => fieldValue.id === field.id
                                  )?.comment || ''
                                }
                                onChange={(e) => handleRemarkChange(e, field.id, c.type)}
                              ></textarea>
                            </div>

                            {/* Done Input */}
                            <div className="w-[8%] flex items-center justify-center">
                              <input
                                className="w-8 h-8"
                                type="checkbox"
                                checked={
                                  userData?.fieldValues?.filter(
                                    (fieldValue) => fieldValue.id === field.id
                                  )[0]?.done || false
                                }
                                onChange={(e) => handleStatusChange(e, field.id, c.type)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default FormFields;
